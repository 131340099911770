import { gql } from '@apollo/client';
import { USER_FIELDS } from '../fragments/User'; // Adjust the path as needed

export const GET_USER = gql`
  query GetUser {
    getUser {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;
