import React, { useState, useEffect } from 'react';
import OnboardingStep, { OnboardingContext } from './OnboardingStep';
import styles from './PartnerEmailStep.module.css';
import { useNavigate } from 'react-router-dom';

interface PartnerEmailStepProps {
    onboardingContext: OnboardingContext;
    onNext: (partnerEmail: string) => void;
    onSkip?: () => void;
    existingValue?: string;
}

const PartnerEmailStep: React.FC<PartnerEmailStepProps> = ({ onboardingContext, onNext, onSkip, existingValue }) => {
    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const [email, setEmail] = useState(existingValue && validateEmail(existingValue) ? existingValue : '');
    const navigate = useNavigate();
    const [buttonEnabled, setButtonEnabled] = useState(false); // Tracks button state

    // Update button state whenever count changes
    useEffect(() => {
        setButtonEnabled(email != ''); // Ensure button is enabled only for valid counts
    }, [email]);

    const handleContinue = () => {
        if (!validateEmail(email)) {
            navigate(`?error=true&message=${encodeURIComponent('Please enter valid email address')}`);
            return;
        }
        onNext(email);
    };

    return (
        <OnboardingStep
            onboardingContext={onboardingContext}
            title="Would you like to add your partner?"
            subtitle="Plii works best when both parents stay in sync!"
            onContinue={handleContinue}
            buttonEnabled={email != ''}
            onSkip={onSkip}
        >
            <div className={styles.container}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Partner’s email"
                />
            </div>
        </OnboardingStep>
    );
};

export default PartnerEmailStep;
