import { UserSummary } from './User';

export type Event = {
    eventID: string;
    title: string;
    owner: EventOwner;
    startDate: string;
    endDate?: string;
    description?: string;
};

export type EventOwner = {
    userID: string;
    viewerRelationship: ViewerRelationshipType;
    userSummary: UserSummary;
}

export type GroupedEvent = {
    date: string;
    summary?: string;
    mainEvents?: Event[];
    relatedEvents?: Event[];
    otherEvents?: Event[];
};

export enum AttentionLevelType {
    INFORMATIONAL = "INFORMATIONAL",
    MAY_NEED_ACTION = "MAY_NEED_ACTION",
    ACTION_REQUIRED = "ACTION_REQUIRED"
}

export type DigestItemType = {
    itemHeader: ItemHeader;
    itemType: string;
    content: Event | GroupedEvent;
    attentionLevel: string;
    additionalContext?: Record<string, unknown>;
};

export type ItemHeader = {
    topTitle?: string;
    mainTitle?: string;
    description?: string;
}

export type DigestSection = {
    sectionTitle?: string;
    sectionDescription?: string;
    sectionType: SectionType;
    items: DigestItemType[];
};

export enum SectionType {
    PRIORITY = "PRIORITY",
    EVENTS = "EVENTS",
}

export enum ViewerRelationshipType {
    SELF = "SELF",
    SPOUSE = "SPOUSE",
    OTHER = "OTHER",
}


export type Digest = {
    generatedAt: string;
    userID: string;
    sections: DigestSection[];
};
