import { Capacitor } from '@capacitor/core';

export const getPlatform = (): string => {
    return Capacitor.getPlatform();
};
  
export const isNative = (): boolean => {
  const platform = Capacitor.getPlatform();
  return platform === 'ios' || platform === 'android';
};

export const isIOS = (): boolean => {
    const platform = Capacitor.getPlatform();
    return platform === 'ios';
};
  

export const isWeb = (): boolean => {
  return Capacitor.getPlatform() === 'web';
};

export const isMobileWeb = (): boolean => {
  if (Capacitor.getPlatform() === 'web') {
    // Check the user agent for common mobile indicators
    return /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent);
  }
  return false;
};

export const isSimulator = (): boolean => {
  return navigator.userAgent.includes("Simulator") ||
  navigator.userAgent.includes('Emulator');
}