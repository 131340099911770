import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

export async function storeGoogleTokens(
    accessToken: string,
    expiresIn: number, // in seconds
    refreshToken?: string,
    idToken?: string,    
  ) {
  
    const expirationTimestamp = Date.now() + expiresIn * 1000; // Current time + expiresIn
  
    await SecureStoragePlugin.set({ key: 'google_access_token', value: accessToken });
    await SecureStoragePlugin.set({ key: 'google_access_token_expiry', value: expirationTimestamp.toString() });
  
    if (refreshToken) {
      await SecureStoragePlugin.set({ key: 'google_refresh_token', value: refreshToken });
    }
  
    if (idToken) {
      await SecureStoragePlugin.set({ key: 'google_id_token', value: idToken });
    }
  
    console.log('Google tokens and expiry stored securely.');
  }

export async function getValidAccessToken(): Promise<string> {
    const accessToken = (await SecureStoragePlugin.get({ key: 'google_access_token' })).value;
    const expiry = (await SecureStoragePlugin.get({ key: 'google_access_token_expiry' })).value;
  
    if (!accessToken || !expiry) {
      throw new Error('No access token found');
    }
  
    const currentTime = Date.now();
  
    // Check if the token is expired
    if (currentTime >= parseInt(expiry, 10)) {
      console.log('Access token expired. Refreshing...');
      const refreshToken = (await SecureStoragePlugin.get({ key: 'google_refresh_token' })).value;
      if (!refreshToken) {
        throw new Error('No refresh token available for renewal');
      }
  
      const newAccessToken = await refreshGoogleAccessToken(refreshToken);
      return newAccessToken; // New token is already stored
    }
  
    return accessToken;
}

async function refreshGoogleAccessToken(refreshToken: string): Promise<string> {
    const response = await fetch('/api/auth/refresh', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });
    
      if (!response.ok) {
        throw new Error('Failed to refresh access token');
      }
    
      const { access_token, expires_in } = await response.json();
    
      // Store the new access token securely
      await SecureStoragePlugin.set({ key: 'google_access_token', value: access_token });
      const expirationTimestamp = Date.now() + expires_in * 1000;
      await SecureStoragePlugin.set({ key: 'google_access_token_expiry', value: expirationTimestamp.toString() });
    
      return access_token;
  }