import React from "react";
import styles from "./DigestItem.module.css";
import { Event } from "../../types/Digest";

type EventItemProps = {
    content: Event;
};

const EventItem: React.FC<EventItemProps> = ({ content }) => (
    <li className={styles.digestItem}>
        <h4 className={styles.title}>{content.title || "No Title"}</h4>
        <p className={styles.description}>{content.description || "No Description"}</p>
        <p className={styles.date}>{new Date(content.startDate).toLocaleString()}</p>
        {content.endDate && (
            <p className={styles.endDate}>
                End: {new Date(content.endDate).toLocaleString()}
            </p>
        )}
    </li>
);

export default EventItem;
