import React from 'react';
import styles from './SecondaryButton.module.css';

interface SecondaryButtonProps {
    onClick: () => void; // Function to execute on click
    children: React.ReactNode; // Button content (e.g., "Log in", "Submit")
    useBlackText?: boolean;
    className?: string; // Optional: Custom styles
    disabled?: boolean; // Optional: Disable the button
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ onClick, children, className = '', useBlackText = false, disabled = false }) => {
    return (
        <button
            type="button"
            className={`${styles.secondaryButton} ${disabled ? styles.disabled : ''} ${useBlackText ? styles.blackText : ''} ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default SecondaryButton;
