import React from 'react';
import Navbar from '../navbar/Navbar'; // Import Navbar component
import styles from './Layout.module.css'; // Import CSS styles

interface NavbarConfig {
    title: string; // Optional title for the Navbar
    profileBubble?: boolean; // Whether to show a profile bubble
    onBack?: () => void; // Optional back button handler
}

interface LayoutProps {
    children: React.ReactNode; // Content of the screen
    navbarConfig?: NavbarConfig; // Navbar configuration object
    overflow?: 'hidden' | 'auto'; // Control vertical overflow behavior
    footer?: React.ReactNode; // Optional footer element
}

const Layout: React.FC<LayoutProps> = ({ children, navbarConfig, overflow = 'hidden', footer }) => {
    return (
        <div className={`${styles.screenView} ${overflow === 'hidden' ? styles.overflowHidden : styles.overflowAuto}`}>
            {navbarConfig && (
                <Navbar
                    title={navbarConfig.title}
                    profileBubble={navbarConfig.profileBubble}
                    onBack={navbarConfig.onBack}
                />
            )}
            <div className={styles.content}>{children}</div>
            {footer && <div className={`${styles.footer}`}>{footer}</div>}
        </div>
    );
};

export default Layout;
