import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import OnboardingStep from './OnboardingStep';
import { GET_USER_EXTERNAL_CALENDAR_LIST } from '../../graphql/queries/getUserExternalCalendarsList';
import { ADD_EXTERNAL_CALENDARS } from '../../graphql/mutations/addExternalCalendars';
import { GET_CALENDAR_CONNECTIONS } from '../../graphql/queries/getCalendarConnections';
import { LOCAL_MODE } from '../../config/local';
import styles from './CalendarListSelectionStep.module.css';
import { CalendarSourceType } from '../../types/Calendar';
import { CalendarPlugin } from 'calendar-plugin';

interface CalendarListSelectionStepProps {
    onboardingContext: {
        stepNumber: number;
        totalSteps: number;
        onBack?: () => void;
    };
    source: CalendarSourceType;
    account: string;
    label?: string;
    onSkip: () => void;
    onNext: (selectedCalendars: { id: string; type: string }[]) => void;
}

const calendarTypes = [
    { value: 'personal', label: 'Personal' },
    { value: 'work', label: 'Work' },
    { value: 'family', label: 'Family' },
];

const CalendarListSelectionStep: React.FC<CalendarListSelectionStepProps> = ({
    onboardingContext,
    source,
    account,
    label,
    onNext,
    onSkip,
}) => {
    const [calendars, setCalendars] = useState<any[]>([]);
    const [selectedCalendars, setSelectedCalendars] = useState<{ id: string; type: string }[]>([]);
    const [buttonEnabled, setButtonEnabled] = useState(false);

    // Mock data for local testing
    const mockCalendars = [
        { id: '1', name: 'Personal Calendar' },
        { id: '2', name: 'Work Calendar' },
        { id: '3', name: 'Family Calendar' },
        { id: '4', name: 'Holidays' },
        { id: '5', name: 'Personal Calendar' },
        { id: '6', name: 'Work Calendar' },
        { id: '7', name: 'Family Calendar' },
        { id: '8', name: 'Holidays' },
        { id: '9', name: 'Personal Calendar' },
        { id: '10', name: 'Work Calendar' },
        { id: '11', name: 'Family Calendar' },
        { id: '12', name: 'Holidays' },
    ];

    // Fetch calendars for the account or use mock data
    const { loading, error, data } = useQuery(GET_USER_EXTERNAL_CALENDAR_LIST, {
        skip: LOCAL_MODE || source == CalendarSourceType.APPLE,
        variables: {
            calendar: {
                source,
                identifier: account,
            },
        },
        fetchPolicy: 'network-only',
    });

    // Fetch calendars for the account or use mock data
    const { loading: userCalendarsLoading, error: userCalendarsError, data: userCalendarsData } = useQuery(
        GET_CALENDAR_CONNECTIONS, {
        skip: LOCAL_MODE || source !== CalendarSourceType.APPLE,
        fetchPolicy: 'network-only',
    });

    const [addExternalCalendars, { loading: savingCalendars }] = useMutation(ADD_EXTERNAL_CALENDARS, {
        onCompleted: () => {
            console.log('Calendars saved successfully.');
            onNext(selectedCalendars); // Proceed to the next step
        },
        onError: (err) => {
            console.error('Failed to save calendars:', err);
        },
    });

    useEffect(() => {
        const fetchCalendars = async () => {
            if (LOCAL_MODE) {
                setCalendars(mockCalendars);
            } else if (source === 'Apple') {
                try {
                    const response = await CalendarPlugin.getCalendars();
                    console.log('Fetched Apple calendars:', response.calendars);
                    if (userCalendarsData?.getCalendarConnections) {
                        type CalendarConnection = {
                            identifier: string; // Ensure this matches your GraphQL query result type
                        };

                        // Extract synced accounts from the connected calendars
                        const syncedAccounts = userCalendarsData.getCalendarConnections.map(
                            (connection: CalendarConnection) => connection.identifier
                        );

                        // Filter out Apple calendars with matching inferred emails
                        const filteredCalendars = response.calendars.filter((calendar: any) => {
                            // Check if the calendar has an inferred email that matches a synced email
                            const inferredEmail = calendar.inferredEmail; // Ensure this field is populated in `CalendarPlugin`
                            return !inferredEmail || !syncedAccounts.includes(inferredEmail);
                        });

                        setCalendars(
                            filteredCalendars.map((calendar: any) => ({
                                id: calendar.id,
                                name: calendar.sourceType === 'Local'
                                    ? `Device - ${calendar.title || 'Unnamed Calendar'}`
                                    : calendar.title || 'Unnamed Calendar',
                            }))
                        );
                    } else {
                        // If no connected calendars, just map all Apple calendars
                        setCalendars(
                            response.calendars.map((calendar: any) => ({
                                id: calendar.id,
                                name: calendar.title,
                            }))
                        );
                    }
                } catch (error) {
                    console.error('Error fetching device calendars:', error);
                }
            } else if (data?.getUserExternalCalendarList) {
                console.log('Fetched external calendars:', data.getUserExternalCalendarList);
                setCalendars(data.getUserExternalCalendarList);
            }
        };

        fetchCalendars();
    }, [data, userCalendarsData, source]);

    useEffect(() => {
        if (error) {
            console.error('Error fetching external calendar list:', error);
            if (error.networkError) console.error('Network Error Details:', error.networkError);
            error.graphQLErrors?.forEach((graphQLError, index) =>
                console.error(`GraphQL Error ${index + 1}:`, graphQLError.message)
            );
        }
    }, [error]);

    useEffect(() => {
        setButtonEnabled(selectedCalendars.some((calendar) => calendar.type));
    }, [selectedCalendars]);

    const handleCalendarSelection = (calendarId: string, checked: boolean) => {
        setSelectedCalendars((prev) => {
            if (checked) {
                const updatedSelections = [...prev, { id: calendarId, type: 'personal' }];
                setButtonEnabled(true); // Enable the button immediately after selection
                return updatedSelections;
            }
            const updatedSelections = prev.filter((calendar) => calendar.id !== calendarId);
            setButtonEnabled(updatedSelections.some((calendar) => calendar.type)); // Update button state
            return updatedSelections;
        });
    };

    const handleTypeChange = (calendarId: string, type: string) => {
        setSelectedCalendars((prev) =>
            prev.map((calendar) =>
                calendar.id === calendarId ? { ...calendar, type } : calendar
            )
        );
    };

    const handleSave = async () => {
        try {
            const calendarInput = {
                source: source,
                identifier: account,
                label: label ? label : '',
            };
            await addExternalCalendars({
                variables: {
                    calendar: calendarInput,
                    entries: selectedCalendars,
                },
            });
        } catch (err) {
            console.error('Error saving calendars:', err);
        }
    };

    const accountName = label ? label : account;

    return (
        <OnboardingStep
            title={`Choose calendars for ${accountName} to sync and categorize them.`}
            subtitle=""
            onboardingContext={onboardingContext}
            buttonEnabled={buttonEnabled}
            onContinue={handleSave}
            onSkip={onSkip}
        >
            {loading && !LOCAL_MODE && <p>Loading calendars...</p>}
            {error && !LOCAL_MODE && <p>Error loading calendars. Please try again.</p>}
            {!loading && !error && (
                <div className={styles.container}>
                    <div className={styles.scrollableContent}>
                        {calendars.map((calendar) => {
                            const isSelected = selectedCalendars.some((c) => c.id === calendar.id);
                            const selectedType =
                                selectedCalendars.find((c) => c.id === calendar.id)?.type || '';

                            return (
                                <div key={calendar.id} className={styles.calendarRow}>
                                    <div className={styles.calendarInfo}>
                                        <label className={styles.checkboxLabel}>
                                            <input
                                                type="checkbox"
                                                checked={isSelected}
                                                onChange={(e) =>
                                                    handleCalendarSelection(calendar.id, e.target.checked)
                                                }
                                            />
                                            {calendar.name}
                                        </label>
                                    </div>
                                    {isSelected && (
                                        <select
                                            className={styles.typeDropdown}
                                            value={selectedType}
                                            onChange={(e) =>
                                                handleTypeChange(calendar.id, e.target.value)
                                            }
                                        >
                                            {calendarTypes.map((type) => (
                                                <option key={type.value} value={type.value}>
                                                    {type.label}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </OnboardingStep>
    );
};

export default CalendarListSelectionStep;
