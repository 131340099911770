import { gql } from '@apollo/client';

export const UPSERT_CHILDREN = gql`
  mutation UpsertChildren($children: [ChildInput!]!) {
    upsertChildren(children: $children) {
      children {
        childID
        name
        gender
        birthday
        currentSchool {
          schoolName
          className
        }
      }
    }
  }
`;