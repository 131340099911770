import { API_BASE_URL } from '../config/api';
import { checkAuthentication } from '../auth/jwt';
import { isNative } from '../platform/platform';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

// Define the options type for fetchWithAuth
export interface FetchOptions extends RequestInit {
    headers?: HeadersInit; // Headers can be an object, map, or array
}

// Helper function to get the token
const getAuthToken = async (): Promise<string | null> => {
    if (!isNative()) {
        return null;
    }
    const isAuthenticated = await checkAuthentication();

    if (isAuthenticated) {
        const tokenResult = await SecureStoragePlugin.get({ key: 'access_token' });
        return tokenResult.value || null; // Ensure we return null if value is undefined
    }

    return null;
};

// Reusable fetch function
export const fetchWithAuth = async (url: string, options: FetchOptions = {}): Promise<Response> => {
    const token = await getAuthToken();

    // Ensure headers exist in the options object
    options.headers = {
        ...options.headers, // Preserve existing headers
        Authorization: token ? `Bearer ${token}` : '', // Add the Authorization header if token exists
    };

    // Concatenate API_BASE_URL with the provided URL
    const fullUrl = `${API_BASE_URL}${url.startsWith('/') ? '' : '/'}${url}`;

    // Perform the fetch
    return fetch(fullUrl, options);
};
