import React from "react";
import DigestItem from "./DigestItem";
import styles from "./DigestCard.module.css";
import hornIcon from "../../assets/icons/horn.svg";
import { DigestItemType, AttentionLevelType } from "../../types/Digest";

type DigestCardProps = {
    item: DigestItemType;
};

const DigestCard: React.FC<DigestCardProps> = ({ item }) => {

    const attentionLevel = item.attentionLevel;
    const enumValue = AttentionLevelType[attentionLevel as keyof typeof AttentionLevelType];
    const isHighAttentionLevel =
        enumValue === AttentionLevelType.MAY_NEED_ACTION ||
        enumValue === AttentionLevelType.ACTION_REQUIRED;

    return (
        <div className={styles.section}>
            {/* Top Header */}
            <div className={`${styles.itemHeader} ${isHighAttentionLevel ? styles.highAttentionLevel : ""}`}>
                <div className={styles.leftHeader}>
                    <div className={styles.topTitle}>{item.itemHeader.topTitle}</div>
                    <div className={styles.mainTitle}>{item.itemHeader.mainTitle}</div>
                </div>

                <div className={styles.headerDivider}></div>

                <div className={styles.rightHeader}>
                    {isHighAttentionLevel &&
                        <div className={styles.attentionHeader} >
                            <div className={styles.hornIconContainer} >
                                <img className={styles.hornIcon} src={hornIcon} />
                            </div>
                        </div>}
                    {item.itemHeader.description && <div className={styles.headerSummary}>
                        {item.itemHeader.description.split("!").map((part, index) => (
                            <p key={`${index}`}>
                                {part.trim() /* Remove any extra whitespace */}
                                {item.itemHeader.description && index < item.itemHeader.description.split("!").length - 1 && "!" /* Add the "!" back */}
                            </p>
                        ))}
                        {/* {item.itemHeader.description.map((desc, index) => (
                            <p key={index}>{desc}</p> // Render each string inside a paragraph
                        ))} */}
                    </div>}
                    {/* <div className={styles.headerSummary}>{item.itemHeader.description}</div> */}
                </div>
            </div>

            {/* Content */}
            <ul className={styles.content}>
                <DigestItem itemType={item.itemType} content={item.content} />
            </ul>
        </div>
    );
};

export default DigestCard;
