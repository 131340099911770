import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import Login from './components/login/Login';
import ExistingLoginScreen from './components/login/ExistingLoginScreen';
// @ts-ignore
import HomePage from './pages/HomePage';
// @ts-ignore
import TermsAndConditions from './components/TermsAndConditions';
// @ts-ignore
import PrivacyPolicy from './components/PrivacyPolicy';
// @ts-ignore
import JoinBeta from './components/JoinBeta';
import { ToastContainer, toast } from 'react-toastify';
// @ts-ignore
import LandingPage from './components/LandingPage';
import 'react-toastify/dist/ReactToastify.css';
import { isWeb, isNative, isMobileWeb } from './platform/platform';
import './App.css';
import { isAppIntroductionComplete, setAppIntroductionComplete } from './introduction/preferences';
import AppIntroductionManager from './components/introduction/AppIntroductionManager';
import { SplashScreen as CapacitorSplashScreen } from '@capacitor/splash-screen';
import SplashScreen from './components/introduction/SplashScreen';
import { App as CapacitorApp } from '@capacitor/app';
import { GOOGLE_CONFIG as IOS_GOOGLE_CONFIG } from './config/ios';
import { Browser } from '@capacitor/browser';
import { exchangeCodeForTokens } from './auth/exchange';
import { fetchVerifier } from './auth/pkce';
import { exchangeGoogleTokensForAccesTokens } from './auth/verify';
import { AUTH_TYPE } from './google/loginHandler';
import { storeGoogleTokens } from './auth/store';
import { GET_USER } from './graphql/queries/getUser';
import OnboardingFlow from './components/onboarding/OnboardingFlow';
import { User } from './types/User';
import { checkAuthentication } from './auth/jwt';
import { LOCAL_MODE, SKIP_ONBOARDING, SHOW_DIGEST_FLOW, SHOW_DATA_LOADING_SCREEN, SHOW_SPLASH_SCREEN, SHOW_LOGIN_SCREEN } from './config/local';
import { CalendarSourceType } from './types/Calendar';
import { EventEmitter } from 'events';
import { AppEventType } from './events/app';
import { Keyboard } from "@capacitor/keyboard";
import DigestView from './components/digest/DigesetView';
import { Digest, AttentionLevelType, SectionType, ViewerRelationshipType } from './types/Digest';
import { GENERATE_DIGEST } from './graphql/queries/generateDigest';
import DataSetupComponent from './components/setup/DataSetupComponent';

export const appEvents = new EventEmitter();

// ProtectedRoute Component
function ProtectedRoute({
  isAuthed,
  user,
  isWeb,
  needsIntro,
  completeIntroductionFunc,
  children
}: {
  isAuthed: boolean | null;
  user: User | null;
  isWeb: boolean | null;
  needsIntro: boolean | null;
  completeIntroductionFunc: () => Promise<void>,
  children: React.ReactNode;
}) {
  const handleSwitchToListView = () => {
    alert("Switching to list view!");
  };
  if (SHOW_DIGEST_FLOW) {
    return <DigestView digest={generateMockDigest()} onSwitchToListView={handleSwitchToListView} />
  }

  if (SHOW_LOGIN_SCREEN) {
    return <Navigate to="/auth/login" replace />;
  }

  // if (needsIntro) {
  //   return <AppIntroductionManager completeIntroduction={completeIntroductionFunc} />; // does not get retriggers
  // }

  if (!SKIP_ONBOARDING && (user && !user.onboardingStatus?.completed)) {
    return <OnboardingFlow user={user} />;
  }

  if (LOCAL_MODE) {
    return <>{children}</>;
  }

  if (!isAuthed || !user) {
    if (isWeb) {
      return <LandingPage />;
    }
    return <Navigate to="/auth/login" replace />;
  }

  return <>{children}</>;
}

function App(): JSX.Element {
  const [appInitialized, setAppInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(LOCAL_MODE ? true : null);
  const [isIntroductionDone, setIsIntroductionDone] = useState<boolean>(false);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [user, setUser] = useState<User | null>(LOCAL_MODE ? generateMockUser() : null);
  const [digestReady, setDigestReady] = useState(false);
  const [digest, setDigest] = useState<Digest | null>(null);

  const appLoaded = () => {
    return !(!appInitialized || loadingAuth || loadingUser || isAuthenticated === null);
  }

  // const sleep = (ms: number): Promise<void> => {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // };

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const platform = isWeb() ? 'web' : isNative() ? 'native' : 'unknown';

  const [fetchDigest, { loading: digestLoading, data: digestData, error: digestError }] = useLazyQuery(GENERATE_DIGEST, {
    fetchPolicy: 'network-only', // Always fetch fresh data
    onCompleted: (data) => {
      console.log('Digest fetched successfully:', data);
      setDigest(data?.generateDigest || null);
      setDigestReady(true);
      navigate("/digest");
    },
    onError: (err) => {
      console.error('Error fetching digest:', err);
      // Optionally handle the error (e.g., retry mechanism or toast message)
    },
  });



  // Apollo Query for fetching user data
  const [fetchUser, { loading: loadingUser }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    context: { skip401Redirect: true },
    onCompleted: async (data) => {
      console.log("-------fetch user calls----------")
      console.log(data);
      setUser(data?.getUser || null);
      console.log(!!data?.getUser);
      setIsAuthenticated(!!data?.getUser);
    },
    onError: async (err) => {
      console.error("Error fetching user:", err.message);
      setUser(null);
      setIsAuthenticated(false);
    },
  });

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;
      if (e.key === "Enter" && target.tagName === "INPUT") {
        target.blur(); // Unfocus the input
        Keyboard.hide(); // Explicitly close the keyboard
      }
    };

    // Add event listener to the document
    document.addEventListener("keydown", handleKeyPress);

    // Clean up on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    const error = searchParams.get('error');
    const message = searchParams.get('message');

    if (error && message) {
      toast.dismiss();
      toast.error(decodeURIComponent(message), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true, // Removes the timer/progress bar
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (message) {
      toast.dismiss();
      toast.success(decodeURIComponent(message), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true, // Removes the timer/progress bar
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (error || message) {
      setSearchParams({});
    }
  }, [searchParams]);

  useEffect(() => {
    // Check if the platform is iOS
    if (isNative()) {
      if (!SHOW_SPLASH_SCREEN && appLoaded()) {
        const rootElement = document.getElementById('root');
        if (rootElement) {
          rootElement.classList.add('iosTopPadding');
        }
      }
    }

    // Cleanup: Remove the class when unmounting
    return () => {
      const rootElement = document.getElementById('root');
      if (rootElement) {
        rootElement.classList.remove('iosTopPadding');
      }
    };
  }, [appInitialized, loadingAuth, loadingUser, isAuthenticated]);

  useEffect(() => {
    const handleReloadUser = async () => {
      console.log('Reloading user...');
      await fetchUser();
    };

    appEvents.on(AppEventType.RELOAD_USER, handleReloadUser);

    // Cleanup listener on unmount
    return () => {
      appEvents.off(AppEventType.RELOAD_USER, handleReloadUser);
    };
  }, [fetchUser]);

  // Handle OAuth redirects
  useEffect(() => {
    let listenerHandle: any;

    const setupListener = async () => {
      listenerHandle = CapacitorApp.addListener('appUrlOpen', async (data: { url: string }) => {
        try {
          if (data.url.startsWith(IOS_GOOGLE_CONFIG.REDIRECT_URI)) {
            Browser.close();
            const url = new URL(data.url);
            const authCode = url.searchParams.get('code');

            if (!authCode) {
              throw new Error('Failed to receive the auth code');
            }

            const verifier = await fetchVerifier();
            const { accessToken, refreshToken, idToken, expiresIn } = await exchangeCodeForTokens(authCode, verifier);

            const isCalendarSync = data.url.includes('calendar');
            const authType = isCalendarSync ? AUTH_TYPE.CALENDAR : AUTH_TYPE.LOGIN;
            const authEmail = await exchangeGoogleTokensForAccesTokens(authType, idToken, accessToken, refreshToken);
            await storeGoogleTokens(accessToken, expiresIn, refreshToken, idToken);

            if (isCalendarSync) {
              console.log('Calendar syncing successful');
              appEvents.emit(AppEventType.CALENDAR_UPDATED, {
                source: CalendarSourceType.GOOGLE,
                account: authEmail,
              });
              return;
            }

            setIsAuthenticated(true);
            await fetchUser();
            navigate('/');
          }
        } catch (error) {
          console.error("Error during login:", error);
          navigate('/?error=Login+failed');
        }
      })
    };

    setupListener();
  }, [navigate, fetchUser]);

  // useEffect(() => {
  //   const fetchInitialData = async () => {
  //     try {
  //       // Simulate time-consuming tasks
  //       console.log("Starting initial calendar sync...");
  //       // await initialCalendarSync();

  //       console.log("Starting initial calendar analysis...");
  //       // await initialCalendarAnalysis();

  //       console.log("Fetching digest...");
  //       fetchDigest(); // Trigger digest fetching
  //     } catch (error) {
  //       console.error("Error during initial setup:", error);
  //       // Optionally, handle errors (e.g., show a toast or retry mechanism)
  //     }
  //   };

  //   if (user?.onboardingStatus?.completed && !digestReady) {
  //     fetchInitialData();
  //   }
  // }, [user, digestReady, navigate]);

  useEffect(() => {
    const initializeApp = async () => {
      console.log("Initializing app...");

      // Skip authentication if LOCAL_MODE is enabled
      if (LOCAL_MODE) {
        setLoadingAuth(false);
        setIsIntroductionDone(true);
        setAppInitialized(true);
        return;
      }

      try {
        let authenticated = false;

        // Perform authentication check
        if (isNative()) {
          authenticated = await checkAuthentication();
        }

        if (authenticated || isMobileWeb()) {
          console.log("User is authenticated or on mobile web, fetching user...");
          await fetchUser();
        } else {
          console.log("User not authenticated.");
          setIsAuthenticated(false);
        }

        // App Introduction Check
        if (!isWeb()) {
          const introComplete = await isAppIntroductionComplete();
          setIsIntroductionDone(introComplete);
        } else {
          setIsIntroductionDone(true);
        }

        // Mark app as initialized
        setAppInitialized(true);

        // Hide splash screen on native platforms
        if (platform !== 'web') {
          CapacitorSplashScreen.hide();
        }

      } catch (error) {
        console.error("Error during app initialization:", error);
      } finally {
        setLoadingAuth(false); // Ensure loading state ends
      }
    };

    initializeApp();
    // Dependencies: Only include stable external functions or constants
  }, [fetchUser, platform]);

  const completeIntroduction = async () => {
    await setAppIntroductionComplete();
    setIsIntroductionDone(true);
  };

  const handleSwitchToListView = () => {
    alert("Switching to list view!");
  };


  // Show splash screen during loading
  if (SHOW_SPLASH_SCREEN || !appLoaded() || SHOW_DATA_LOADING_SCREEN || ((!digestReady || digest == null) && user?.onboardingStatus?.completed)) {
    if (!SHOW_SPLASH_SCREEN && (SHOW_DATA_LOADING_SCREEN || (!digestReady || digest == null) && user?.onboardingStatus?.completed)) {
      return <DataSetupComponent user={user} digestReady={digestReady} fetchDigest={fetchDigest} />;
    }

    // console.log("--------------------------------");
    // console.log("appInitialized: " + appInitialized);
    // console.log("loadingAUth: " + loadingAuth);
    // console.log("loadingUser: " + loadingUser);
    // console.log("Authenticated: " + isAuthenticated);
    if (isWeb()) {
      return <></>;
    }
    return <SplashScreen />;
  }

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/login/existing" element={<ExistingLoginScreen />} />
        <Route
          path="/"
          element={
            <ProtectedRoute
              isAuthed={isAuthenticated}
              user={user}
              isWeb={platform == 'web'}
              needsIntro={!isIntroductionDone}
              completeIntroductionFunc={completeIntroduction}
            >
              {user && <HomePage user={user} />}
            </ProtectedRoute>
          }
        />
        <Route
          path="/digest"
          element={
            digest ? (
              <DigestView digest={SHOW_DIGEST_FLOW ? generateMockDigest() : digest} onSwitchToListView={handleSwitchToListView} />
            ) : (
              <p>Loading digest...</p> // Fallback UI while digest is being fetched
            )
          }
        />
        <Route path="/join-beta" element={platform === 'web' ? <JoinBeta /> : <Navigate to="/" replace />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </>
  );
}

// Mock User for local mode
function generateMockUser(): User {
  return {
    id: "testUser",
    email: 'localuser@example.com',
    firstName: 'Local',
    lastName: 'User',
    profilePictureUrl: '',
    onboardingStatus: { completed: false },
  };
}

function generateMockDigest(): Digest {
  return {
    generatedAt: "2024-12-29T12:00:00Z",
    userID: "123",
    sections: [
      {
        "sectionDescription": "",
        "sectionType": SectionType.PRIORITY,
        "items": [
          {
            "itemHeader": {
              "topTitle": "Sunday",
              "mainTitle": "JAN 19",
              "description":
                "Heads up! We noticed an early dismissal at your kid's school.",
            },
            "itemType": "groupedEvent",
            "additionalContext": {
              "UserAction": {
                "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460": [
                  "Caretaker"
                ]
              }
            },
            "attentionLevel": "ACTION_REQUIRED",
            "content": {
              "date": "2025-01-20",
              "mainEvents": [
                {
                  "eventID": "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460",
                  "title": "School Closed",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T00:00:00Z",
                  "endDate": undefined,
                }
              ],
              "relatedEvents": [
                {
                  "eventID": "conflicting_event_1",
                  "title": "Min / Han",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T12:00:00Z",
                  "endDate": "2025-01-20T13:00:00Z",
                },
                {
                  "eventID": "conflicting_event_2_spouse",
                  "title": "Work Event",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SPOUSE,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T12:00:00Z",
                  "endDate": "2025-01-20T13:00:00Z",
                }
              ],
              "otherEvents": [
                {
                  "eventID": "other_event_1",
                  "title": "Family Dinner",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T15:00:00Z",
                  "endDate": "2025-01-20T16:00:00Z",
                }
              ],
            },
          },
          {
            "itemHeader": {
              "topTitle": "Sunday",
              "mainTitle": "JAN 23",
              "description": "",
            },
            "itemType": "groupedEvent",
            "additionalContext": {
              "UserAction": {
                "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460": [
                  "Caretaker"
                ]
              }
            },
            "attentionLevel": "INFORMATIONAL",
            "content": {
              "date": "2025-01-19",
              "mainEvents": [
                {
                  "eventID": "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460",
                  "title": "School Closed",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T00:00:00Z",
                  "endDate": undefined,
                }
              ],
            },
          }
        ]
      },
      {
        "sectionDescription": "",
        "sectionType": SectionType.EVENTS,
        "items": [
          {
            "itemHeader": {
              "topTitle": "Sunday",
              "mainTitle": "JAN 19",
              "description":
                "Heads up! We noticed an early dismissal at your kid's school.",
            },
            "itemType": "groupedEvent",
            "additionalContext": {
              "UserAction": {
                "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460": [
                  "Caretaker"
                ]
              }
            },
            "attentionLevel": "ACTION_REQUIRED",
            "content": {
              "date": "2025-01-20",
              "mainEvents": [
                {
                  "eventID": "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460",
                  "title": "School Closed",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T00:00:00Z",
                  "endDate": undefined,
                }
              ],
              "relatedEvents": [
                {
                  "eventID": "conflicting_event_1",
                  "title": "Min / Han",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T12:00:00Z",
                  "endDate": "2025-01-20T13:00:00Z",
                },
                {
                  "eventID": "conflicting_event_2_spouse",
                  "title": "Work Event",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SPOUSE,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T12:00:00Z",
                  "endDate": "2025-01-20T13:00:00Z",
                }
              ],
              "otherEvents": [
                {
                  "eventID": "other_event_1",
                  "title": "Family Dinner",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T15:00:00Z",
                  "endDate": "2025-01-20T16:00:00Z",
                }
              ],
            },
          },
          {
            "itemHeader": {
              "topTitle": "Thursday",
              "mainTitle": "JAN 23",
              "description": "",
            },
            "itemType": "groupedEvent",
            "additionalContext": {
              "UserAction": {
                "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460": [
                  "Caretaker"
                ]
              }
            },
            "attentionLevel": "INFORMATIONAL",
            "content": {
              "date": "2025-01-19",
              "mainEvents": [
                {
                  "eventID": "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460",
                  "title": "School Closed",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T00:00:00Z",
                  "endDate": undefined,
                }
              ],
            },
          },
          {
            "itemHeader": {
              "topTitle": "Friday",
              "mainTitle": "JAN 24",
              "description":
                "Heads up! School is closed.",
            },
            "itemType": "groupedEvent",
            "additionalContext": {
              "UserAction": {
                "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460": [
                  "Caretaker"
                ]
              }
            },
            "attentionLevel": "ACTION_REQUIRED",
            "content": {
              "date": "2025-01-20",
              "mainEvents": [
                {
                  "eventID": "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460",
                  "title": "School Closed",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T00:00:00Z",
                  "endDate": undefined,
                }
              ],
              "relatedEvents": [
                {
                  "eventID": "conflicting_event_1",
                  "title": "Min / Han",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T12:00:00Z",
                  "endDate": "2025-01-20T13:00:00Z",
                },
                {
                  "eventID": "conflicting_event_2_spouse",
                  "title": "Work Event",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SPOUSE,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T12:00:00Z",
                  "endDate": "2025-01-20T13:00:00Z",
                }
              ],
              "otherEvents": [
                {
                  "eventID": "other_event_1",
                  "title": "Family Gameday",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T15:00:00Z",
                  "endDate": "2025-01-20T16:00:00Z",
                }
              ],
            },
          },
          {
            "itemHeader": {
              "topTitle": "Saturday",
              "mainTitle": "JAN 25",
              "description": "",
            },
            "itemType": "groupedEvent",
            "additionalContext": {
              "UserAction": {
                "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460": [
                  "Caretaker"
                ]
              }
            },
            "attentionLevel": "INFORMATIONAL",
            "content": {
              "date": "2025-01-19",
              "mainEvents": [
                {
                  "eventID": "_6p334hi268oj0b9n8d0jgb9k6t13eb9p6goj6ba66gr3egpo8p13cca460",
                  "title": "Playdate with Rohan",
                  "owner": {
                    "userID": "33LAyJNXFYSEnRL8bIv8XveYLKG2",
                    "viewerRelationship": ViewerRelationshipType.SELF,
                    "userSummary": {
                      "profilePictureUrl": "https://storage.googleapis.com/plii-profile-picture/profile_pictures/33LAyJNXFYSEnRL8bIv8XveYLKG2/5083a959-349e-4411-a032-df01daac7806.jpg"
                    }
                  },
                  "startDate": "2025-01-20T00:00:00Z",
                  "endDate": undefined,
                }
              ],
            },
          },
        ]
      },
    ],
  }
};

export default App;
