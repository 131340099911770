import React, { useRef, useState } from 'react';
import styles from './ProfilePictureUpload.module.css';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { ActionSheet } from '@capacitor/action-sheet';
import { isSimulator, isWeb } from '../../platform/platform';
import cameraIcon from '../../assets/icons/camera.svg';
import noUser from '../../assets/no-user.png';

interface ProfilePictureUploadProps {
  onComplete: (croppedImage: string | null) => void;
  initialImage?: string;
  buttonText?: string;
  flowType: FlowType; // Determines styles
}

export enum FlowType {
  Onboarding = 'onboarding',
  Profile = 'profile',
}

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({
  onComplete,
  initialImage = null,
  buttonText = 'Upload Photo',
  flowType = FlowType.Onboarding,
}) => {
  const [originalImageSrc, setOriginalImageSrc] = useState<string | undefined>(initialImage && initialImage !== '' ? initialImage : undefined);
  const [imageSrc, setImageSrc] = useState<string | undefined>('');
  const [croppedSrc, setCroppedSrc] = useState<string | null>(null);
  const cropperInstance = useRef<Cropper | null>(null);
  const cropperRef = useRef<HTMLImageElement | null>(null);

  const containerClass =
    flowType === FlowType.Onboarding ? styles.onboardingContainer : styles.profileContainer;
  const buttonClass =
    flowType === FlowType.Onboarding ? styles.onboardingButton : styles.profileButton;

  const openActionSheet = async () => {
    if (isWeb()) {
      handleSelectFromAlbumWeb();
      return;
    }
    const result = await ActionSheet.showActions({
      title: 'Choose an option',
      message: 'How would you like to upload your photo?',
      options: [
        { title: 'Take Photo' },
        { title: 'Select from Album' },
        { title: 'Cancel' },
      ],
    });

    if (result.index === 0) {
      handleTakePhotoNative(); // Call take photo logic
    } else if (result.index === 1) {
      handleSelectFromAlbumNative(); // Call select from album logic
    }
  };
  // Native flow (iOS/Android)
  const handleTakePhotoNative = async () => {
    try {
      if (isSimulator()) {
        setOriginalImageSrc(noUser);
        setImageSrc(noUser);
        setCroppedSrc(null);
        return;
      }

      const photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: true, // Enable native cropper
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
      });

      if (photo.dataUrl) {
        setCroppedSrc(photo.dataUrl);
        setImageSrc(photo.dataUrl); // Native cropper handles cropping
        onComplete(photo.dataUrl);
      }
    } catch (error) {
      console.error('Error capturing photo:', error);
    }
  };

  const handleSelectFromAlbumNative = async () => {
    try {
      if (isSimulator()) {
        setOriginalImageSrc(noUser);
        setImageSrc(noUser);
        setCroppedSrc(null);
        return;
      }

      const photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: true, // Enable native cropper
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Photos,
      });

      if (photo.dataUrl) {
        setCroppedSrc(photo.dataUrl);
        setImageSrc(photo.dataUrl); // Native cropper handles cropping
        onComplete(photo.dataUrl);
      }
    } catch (error) {
      console.error('Error selecting photo from album:', error);
    }
  };

  const handleSelectFromAlbumWeb = async () => {
    try {
      const photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: false, // No native cropper for web
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Photos,
      });

      if (photo.dataUrl) {
        setOriginalImageSrc(photo.dataUrl);
        setImageSrc(photo.dataUrl);
        setCroppedSrc(null);
      }
    } catch (error) {
      console.error('Error selecting photo from album:', error);
    }
  };

  const initializeCropper = () => {
    if (cropperRef.current) {
      cropperInstance.current = new Cropper(cropperRef.current, {
        aspectRatio: 1,
        viewMode: 1,
        dragMode: 'move',
      });
    }
  };

  const handleCrop = () => {
    if (cropperInstance.current) {
      const croppedCanvas = cropperInstance.current.getCroppedCanvas();
      const croppedDataUrl = croppedCanvas.toDataURL('image/jpeg');
      setCroppedSrc(croppedDataUrl);
      setImageSrc(croppedDataUrl);
      cropperInstance.current?.destroy();
      cropperInstance.current = null;
      onComplete(croppedDataUrl);
    }
  };

  const handleRecrop = () => {
    setImageSrc(originalImageSrc);
    setCroppedSrc(null);
  };

  const handleConfirm = () => {
    onComplete(croppedSrc);
    setCroppedSrc(null);
  };

  const handleReset = () => {
    setImageSrc(undefined);
    setCroppedSrc(null);
    cropperInstance.current?.destroy();
    cropperInstance.current = null;
  };

  if (!isWeb()) {
    // Native flow for iOS/Android
    return (
      <div className={`${styles.uploadContainer} ${containerClass}`}>
        {/* Wrapper container for both image and camera button */}
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            {/* Profile Picture Preview */}
            <img
              src={croppedSrc || imageSrc || originalImageSrc || noUser} // Placeholder for empty state
              alt="Profile Preview"
              className={styles.imagePreview}
            />
            {/* Camera Button */}
          </div>
          <button className={styles.cameraButton} onClick={openActionSheet}>
            <img src={cameraIcon} className={styles.cameraIcon} />
          </button>
        </div>
      </div>
    );
  }

  // Web flow with React Cropper
  return (
    <div className={`${styles.uploadContainer} ${containerClass}`}>
      {!imageSrc ? (
        <>
          {/* Wrapper container for both image and camera button */}
          <div className={styles.imageWrapper}>
            <div className={styles.imageContainer}>
              {/* Profile Picture Preview */}
              <img
                src={croppedSrc || imageSrc || originalImageSrc || noUser} // Placeholder for empty state
                alt="Profile Preview"
                className={styles.imagePreview}
              />
              {/* Camera Button */}
            </div>
            <button className={styles.cameraButton} onClick={openActionSheet}>
              <img src={cameraIcon} className={styles.cameraIcon} />
            </button>
          </div>
        </>
      ) : (
        <>
          {!croppedSrc ? (
            <>
              <img
                ref={cropperRef}
                src={imageSrc}
                alt="To Crop"
                className={styles.cropPreview}
                onLoad={initializeCropper}
              />
              <button onClick={handleCrop} className={buttonClass}>
                Crop
              </button>
              <button onClick={handleReset} className={styles.skipButton}>
                Reset
              </button>
            </>
          ) : (
            <>
              <div className={styles.imageWrapper}>
                <div className={styles.imageContainer}>
                  {/* Profile Picture Preview */}
                  <img
                    src={croppedSrc} // Placeholder for empty state
                    alt="Profile Preview"
                    className={styles.imagePreview}
                  />
                  {/* Camera Button */}
                </div>
                <button className={styles.cameraButton} onClick={openActionSheet}>
                  <img src={cameraIcon} className={styles.cameraIcon} />
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProfilePictureUpload;
