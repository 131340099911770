import React, { useState, useEffect, useRef } from 'react';
import { fetchWithAuth } from '../../request/backend';
import { useLazyQuery } from '@apollo/client';
import { GET_SYNC_STATUS } from '../../graphql/queries/getSyncStatus';
import { GET_ANALYSIS_STATUS } from '../../graphql/queries/getAnalysisStatus';
import styles from './DataSetupComponent.module.css'; // Import the CSS module
import Logo from '../../assets/primary-logo.svg'; // Import the logo
import { SHOW_DATA_LOADING_SCREEN } from '../../config/local';

const DataSetupComponent = ({
    user,
    digestReady,
    fetchDigest,
}: {
    user: any;
    digestReady: boolean;
    fetchDigest: () => void;
}) => {
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('Initializing...');
    const [syncComplete, setSyncComplete] = useState(false);
    const [analysisComplete, setAnalysisComplete] = useState(false);
    const [initialSyncRequestComplete, setInitialSyncRequestComplete] = useState(false);
    const [syncRange, setSyncRange] = useState<{ start: string; end: string } | null>(null);
    const [updatedAfter, setUpdatedAfter] = useState<string | null>(null);
    const [analysisTaskID, setAnalysisTaskID] = useState<string | null>(null);
    const progressRef = useRef(0); // Ref to store the current progress value

    const [checkSyncStatus, { data, loading, error }] = useLazyQuery(GET_SYNC_STATUS, {
        fetchPolicy: 'network-only',
    });

    const [checkAnalysisStatus, { data: analysisData, loading: analysisLoading, error: analysisError }] = useLazyQuery(
        GET_ANALYSIS_STATUS,
        { fetchPolicy: 'network-only' }
    );

    const startInitialSync = async () => {
        if (SHOW_DATA_LOADING_SCREEN) {
            setStatus('Starting initial sync...');
            setProgress(25);
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay
            await mockCheckSyncStatus();
        } else {
            try {
                setStatus('Starting initial sync...');
                setProgress(25);
                await fetchWithAuth('/api/calendar/sync/initial', { method: 'POST', body: JSON.stringify({}) });
                setInitialSyncRequestComplete(true);
            } catch (err) {
                console.error('Error starting initial sync:', err);
                setStatus('Error during sync. Please retry.');
            }
        }
    };

    const startAnalysisRequest = async () => {
        if (!syncRange || !updatedAfter) {
            console.log("required values not present");
            return;
        }

        try {
            setStatus('Analyzing your events...');
            setProgress(60);
            const response = await fetchWithAuth('/api/calendar/sync/analyze', {
                method: 'POST',
                body: JSON.stringify({
                    syncRange: {
                        start: syncRange.start, // Adjust range as needed
                        end: syncRange.end,
                    },
                    syncType: 'INITIAL',
                }),
            });
            const data = await response.json();
            setAnalysisTaskID(data.taskId);
        } catch (err) {
            console.error('Error starting analysis:', err);
            setStatus('Error during analysis. Please retry.');
        }
    };

    const pollAnalysisStatus = async () => {
        if (!analysisTaskID) {
            return;
        }

        let delay = 10; // Start with a 500ms delay
        const maxDelay = 3000; // Maximum delay of 3 seconds

        const poll = async () => {
            try {
                const { data: analysisData } = await checkAnalysisStatus({
                    variables: { taskID: analysisTaskID },
                });
                const analysisStatus = analysisData?.getAnalysisStatus?.status;

                if (analysisStatus === 'COMPLETED') {
                    setStatus('Analysis completed!');
                    setAnalysisComplete(true);
                    setProgress(85);
                    return; // Stop polling
                } else if (analysisStatus === 'ERROR') {
                    setStatus('Error during analysis. Please retry.');
                    return; // Stop polling
                }
            } catch (err) {
                console.error('Error checking analysis status:', err);
            }

            // Use exponential backoff, but cap at maxDelay
            delay = Math.min(delay * 2, maxDelay);
            setTimeout(poll, delay); // Schedule the next poll
        };

        poll(); // Start the polling
    };

    const mockCheckSyncStatus = async () => {
        const interval = setInterval(() => {
            setStatus('Checking sync status...');
            if (progressRef.current >= 50) {
                clearInterval(interval);
                setStatus('Sync completed!');
                setSyncComplete(true);
                return;
            }
            setProgress((prevProgress) => {
                const newProgress = Math.min(prevProgress + 5, 50);
                if (progressRef.current < 50) {
                    progressRef.current = newProgress; // Update the ref with the latest value
                }
                return newProgress;
            });
        }, 500);
    };

    useEffect(() => {
        const fetchAndPollSyncStatus = async () => {
            let delay = 10; // Start with a 500ms delay
            const maxDelay = 3000; // Maximum delay of 3 seconds

            const poll = async () => {
                try {
                    const { data: initialData } = await checkSyncStatus({
                        variables: { syncType: 'INITIAL' },
                    });

                    const syncStatus = initialData?.getSyncStatus;

                    if (syncStatus?.status === 'COMPLETED') {
                        // Sync already completed; set status and proceed
                        setStatus('Sync completed!');
                        setSyncComplete(true);
                        setProgress(50);
                        setSyncRange(syncStatus.syncRange);
                        setUpdatedAfter(syncStatus.createdAt);
                        return; // Exit early, no need to poll further
                    }

                    if (syncStatus?.status === 'ERROR') {
                        // Sync failed; set error status
                        setStatus('Error during sync. Please retry.');
                        return; // Exit early
                    }
                } catch (err) {
                    console.error('Error fetching sync status:', err);
                }

                // Use exponential backoff for polling delay
                delay = Math.min(delay * 2, maxDelay);
                setTimeout(poll, delay); // Schedule the next poll
            };

            // Fetch once and start polling
            await poll();
        };

        if (initialSyncRequestComplete && !syncComplete && !SHOW_DATA_LOADING_SCREEN) {
            fetchAndPollSyncStatus();
        }
    }, [initialSyncRequestComplete, syncComplete, checkSyncStatus]);

    useEffect(() => {
        const analyzeEvents = async () => {
            if (SHOW_DATA_LOADING_SCREEN) {
                setStatus('Analyzing your events...');
                setProgress(75);

                // Simulate event analysis
                await new Promise((resolve) => setTimeout(resolve, 2000));
                setAnalysisComplete(true);
                return;
            }
            startAnalysisRequest();
        };

        if (syncComplete && !analysisTaskID) {
            analyzeEvents();
        }
    }, [syncComplete, syncRange, updatedAfter]);

    useEffect(() => {
        if (analysisTaskID && !analysisComplete) {
            pollAnalysisStatus();
        }
    }, [analysisTaskID, analysisComplete]);

    useEffect(() => {
        const generateDigest = async () => {
            setStatus('Generating digest...');
            setProgress(90);

            if (SHOW_DATA_LOADING_SCREEN) {
                await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate delay
                setProgress(100);
                setStatus('Setup complete!');
            } else {
                try {
                    fetchDigest();
                    setProgress(100);
                    setStatus('Setup complete!');
                } catch (err) {
                    console.error('Error generating digest:', err);
                    setStatus('Error generating digest. Please retry.');
                }
            }
        };

        if (analysisComplete) {
            generateDigest();
        }
    }, [analysisComplete, fetchDigest]);

    useEffect(() => {
        if (SHOW_DATA_LOADING_SCREEN || (user?.onboardingStatus?.completed && !digestReady)) {
            startInitialSync();
        }
    }, [user, digestReady]);

    return (
        <div className={styles.container}>
            <img src={Logo} alt="plii logo" className={styles.logo} />
            <p className={styles.message}>
                {progress === 0
                    ? "Hang tight! We’re fetching your data to build your personalized Digest..."
                    : status}
            </p>
            <div className={styles.progressBarContainer}>
                <div
                    className={styles.progressBar}
                    style={{
                        width: `${progress}%`,
                    }}
                ></div>
            </div>
            <p className={styles.percentage}>{progress}%</p>
        </div>
    );
};

export default DataSetupComponent;
