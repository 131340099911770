import React from "react";
import styles from "./DigestItem.module.css";
import { Event, GroupedEvent } from "../../types/Digest";
import EventItem from "./EventItem";
import GroupedEventItem from "./GroupedEventItem";

type DigestItemProps = {
    itemType: string;
    content: Event | GroupedEvent;
    additionalContext?: Record<string, unknown>;
};

const DigestItem: React.FC<DigestItemProps> = ({ itemType, content }) => {
    switch (itemType) {
        case "event":
            return <EventItem content={content as Event} />;
        case "groupedEvent":
            return <GroupedEventItem content={content as GroupedEvent} />;
        default:
            return (
                <li className={styles.digestItem}>
                    <p className={styles.unsupportedType}>Unsupported content type</p>
                </li>
            );
    }
};

export default DigestItem;
