import React, { useState, useCallback, useEffect, useRef } from 'react';
import Select, { StylesConfig, SingleValue } from 'react-select';
import debounce from 'lodash.debounce';
import { fetchWithAuth } from '../../request/backend';

interface OptionType {
  value: string;
  label: string;
  [key: string]: any;
}

interface DynamicTypeaheadSelectProps<T extends OptionType> {
  placeholder: string; // Placeholder text for the input
  apiEndpoint: string; // API endpoint to fetch results
  transformFunction: (rawData: any[]) => T[];
  preLoadOptions: T[]; // Preloaded Options for the dropdown
  updateOptions: (options: T[]) => void;
  value: string; // Current value
  onChange: (value: string) => void; // Function to handle value changes
  label?: string; // Optional label for the field
  formatOption?: (option: T) => React.ReactNode; // Custom formatter for option rendering
  minInputLength?: number; // Minimum characters to trigger search
  onFocus?: () => void; // New focus handler
  onBlur?: () => void;  // New blur handler
}

const DynamicTypeaheadSelect = <T extends OptionType>({
  placeholder,
  apiEndpoint,
  transformFunction,
  preLoadOptions,
  updateOptions,
  value,
  onChange,
  label,
  formatOption,
  onFocus,
  onBlur,
  minInputLength = 2, // Default to 2 characters for triggering search
}: DynamicTypeaheadSelectProps<T>) => {
  const [options, setOptions] = useState<T[]>(preLoadOptions); // Dynamically loaded options
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Fetch options dynamically from the API
  const fetchOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < minInputLength) {
        setOptions(preLoadOptions);
        return;
      }

      setIsLoading(true);
      try {
        const response = await fetchWithAuth(`${apiEndpoint}?query=${encodeURIComponent(inputValue)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const rawData = await response.json();

        // Use the transformFunction to process raw data
        const transformedData = transformFunction(rawData);
        setOptions(transformedData);
        updateOptions(transformedData);
      } catch (error) {
        console.error('Error fetching options:', error);
        setOptions(preLoadOptions);
      } finally {
        setIsLoading(false);
      }
    },
    [apiEndpoint, minInputLength, preLoadOptions]
  );

  // Debounced version of fetchOptions to limit API calls
  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 300), [fetchOptions]);

  // Handle user input in the dropdown
  const handleInputChange = (inputValue: string) => {
    debouncedFetchOptions(inputValue);
  };

  // Handle selection changes
  const handleChange = (selectedOption: SingleValue<T>) => {
    onChange(selectedOption?.value || ''); // Safe access to the value
  };

  // Handle outside click to unfocus
  const handleOutsideClick = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      onBlur?.();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // Custom styles for React Select
  const customStyles: StylesConfig<T, false> = {
    control: (provided, state) => ({
      ...provided,
      padding: '0.5185rem', // Match the previous input style
      textAlign: 'center',
      borderRadius: '0.5rem',
      background: 'white',
      fontSize: '1.0625rem',
      fontWeight: '500',
      lineHeight: '140%',
      boxShadow: 'none', // Remove default shadow
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0.5rem',
      background: 'white',
      marginTop: '0.5rem',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#ddd' // Selected option background
        : state.isFocused
          ? '#f5f5f5' // Focused option background
          : 'white',
      color: 'black',
      padding: '0.5rem',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#888',
      fontSize: '1.0625rem',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#333',
      fontSize: '1.0625rem',
    }),
  };

  return (
    <div>
      <Select
        placeholder={placeholder}
        options={options}
        formatOptionLabel={formatOption}
        value={options.find((option) => option.value === value)}
        onChange={handleChange}
        onInputChange={handleInputChange}
        styles={customStyles}
        onFocus={onFocus} // Attach the onFocus handler
        onBlur={onBlur}   // Attach the onBlur handler
        isClearable
        isSearchable
        isLoading={isLoading}
        isMulti={false}
        noOptionsMessage={() => 'No results found'}
      />
    </div>
  );
};

export default DynamicTypeaheadSelect;
