import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import CalendarSync, { SyncedAccount } from '../profile/CalendarSync';
import OnboardingStep from './OnboardingStep';
import { GET_CALENDAR_CONNECTIONS } from '../../graphql/queries/getCalendarConnections';
import { LOCAL_MODE } from '../../config/local';
import { useNavigate } from 'react-router-dom';
import { AUTH_TYPE, handleLoginWithGoogle } from '../../google/loginHandler';
import { REMOVE_CALENDAR_CONNECTION } from '../../graphql/mutations/removeCalendarConnection';
import { appEvents } from '../../App';
import { AppEventType } from '../../events/app';
import { CalendarPlugin } from 'calendar-plugin';
import { CalendarSourceType } from '../../types/Calendar';

interface CalendarSyncStepProps {
    onboardingContext: {
        stepNumber: number;
        totalSteps: number;
        onBack?: () => void;
    };
    onNext: (name: string) => void;
}

const CalendarSyncStep: React.FC<CalendarSyncStepProps> = ({
    onboardingContext,
    onNext,
}) => {
    const [syncedAccounts, setSyncedAccounts] = useState<SyncedAccount[]>(LOCAL_MODE ? [
        { provider: 'Google', identifier: 'johndoe@gmail.com' },
        { provider: 'Apple', identifier: 'SOME IDENTIFIER', label: "Local device" },
    ] : []);
    const [buttonEnabled, setButtonEnabled] = useState(false); // Tracks button state
    const [additionalButtons, setAdditionalButtons] = useState<
        { label: string; onClick: () => void; type: 'primary' | 'secondary' }[]
    >([]);
    const navigate = useNavigate();

    // Use Apollo Client's useQuery hook to fetch calendar connections
    const { loading, error, data, refetch } = useQuery(GET_CALENDAR_CONNECTIONS, {
        skip: LOCAL_MODE,
        fetchPolicy: 'network-only',
    });

    const [removeCalendarConnection] = useMutation(REMOVE_CALENDAR_CONNECTION);

    // Update syncedAccounts when data is fetched
    useEffect(() => {
        if (data?.getCalendarConnections) {
            const fetchedAccounts: SyncedAccount[] = data.getCalendarConnections.map((connection: any) => ({
                provider: connection.source === 'Google' ? 'Google' : 'Apple',
                identifier: connection.identifier,
                label: connection.label,
            }));
            console.log(fetchedAccounts);
            setSyncedAccounts(fetchedAccounts);
        }
    }, [data]);

    // Update button state whenever syncedAccounts changes
    useEffect(() => {
        setButtonEnabled(syncedAccounts.length > 0); // Enable button only when accounts exist
    }, [syncedAccounts]);

    // Handle adding an account
    const handleAddAccount = async (provider: 'Google' | 'Apple') => {
        if (provider == 'Google') {
            await handleLoginWithGoogle(AUTH_TYPE.CALENDAR);
        } else if (provider === 'Apple') {
            try {
                const response = await CalendarPlugin.requestCalendarPermissions();
                if (response.granted) {
                    console.log('Calendar permissions granted.');
                    appEvents.emit(AppEventType.CALENDAR_UPDATED, { source: CalendarSourceType.APPLE, account: response.deviceIdentifier, label: response.deviceName });
                } else {
                    console.error('Calendar permissions not granted.');
                }
            } catch (error) {
                console.error('Error requesting calendar permissions:', error);
            }
        }
    };

    // Handle removing an account
    const handleRemoveAccount = async (identifier: string) => {
        try {
            const accountToRemove = syncedAccounts.find(
                (account) => account.identifier === identifier
            );
            if (!accountToRemove) {
                console.error(`Account with email ${identifier} not found`);
                return;
            }

            console.log(accountToRemove.provider);
            console.log(accountToRemove.identifier);

            // Execute the mutation
            await removeCalendarConnection({
                variables: {
                    calendar: {
                        source: accountToRemove.provider,
                        identifier: accountToRemove.identifier,
                    },
                },
            });

            // Update local state after successful mutation
            setSyncedAccounts((prev) =>
                prev.filter((account) => account.identifier !== identifier)
            );
        } catch (error) {
            console.log(error);
            navigate(`?error=true&message=${encodeURIComponent('There was an error removing the account.')}`);
        }
    };

    const handleContinue = () => {
        onNext('test');
    };

    return (
        <OnboardingStep
            title="Sync Your Calendars"
            subtitle="Connect your calendars to stay organized."
            onboardingContext={onboardingContext}
            buttonEnabled={buttonEnabled}
            onContinue={handleContinue}
            additionalButtons={additionalButtons} // Buttons dynamically set by CalendarSync
        >
            <CalendarSync
                existingConnections={syncedAccounts} // Prefetched accounts
                onAccountAdd={handleAddAccount} // Pass handleAddAccount directly
                onAccountRemove={handleRemoveAccount} // Pass handleRemoveAccount directly
                setAdditionalButtons={setAdditionalButtons} // Pass down callback to set buttons
                loading={loading} // Pass loading state
                error={error} // Pass error state
            />
        </OnboardingStep>
    );
};

export default CalendarSyncStep;
