import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import OnboardingStep from './OnboardingStep';
import { OnboardingContext } from './OnboardingStep';
import styles from './NameStep.module.css';
import { SAVE_USER_PROFILE } from '../../graphql/mutations/saveUserProfile';
import { LOCAL_MODE } from '../../config/local';
import { useNavigate } from 'react-router-dom';

interface NameStepProps {
  onboardingContext: OnboardingContext;
  onNext: (firstName: string, lastName: string) => void;
  existingValues?: { firstName: string; lastName: string };
}

const NameStep: React.FC<NameStepProps> = ({ onboardingContext, onNext, existingValues }) => {
  const [firstName, setFirstName] = useState(existingValues?.firstName || '');
  const [lastName, setLastName] = useState(existingValues?.lastName || '');
  const navigate = useNavigate();

  const [buttonEnabled, setButtonEnabled] = useState(false); // Tracks button state

  // Update button state whenever count changes
  useEffect(() => {
    setButtonEnabled(firstName != '' && lastName != ''); // Ensure button is enabled only for valid counts
  }, [firstName, lastName]);

  const [saveUserProfile, { loading }] = useMutation(SAVE_USER_PROFILE, {
    onCompleted: () => {
      console.log('User profile saved successfully');
      onNext(firstName, lastName); // Move to the next step after successful mutation
    },
    onError: (error) => {
      console.error('Failed to save user profile:', error);
      navigate(`?error=true&message=${encodeURIComponent('Failed to save your profile. Please try again.')}`);
    },
  });

  const handleContinue = () => {
    if (LOCAL_MODE) {
      onNext(firstName, lastName);
      return;
    }

    if (firstName.trim() && lastName.trim()) {
      saveUserProfile({
        variables: {
          input: {
            firstName,
            lastName,
          },
        },
      });
    } else {
      navigate(`?error=true&message=${encodeURIComponent('Please fill out both fields.')}`);
    }
  };

  return (
    <OnboardingStep
      onboardingContext={onboardingContext}
      title="Great to see you here!"
      subtitle="What is your name?"
      buttonEnabled={firstName != '' && lastName != ''}
      onContinue={handleContinue}
    >
      <div className={styles.inputContainer}>
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="First Name"
          className={styles.inputField}
        />
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Last Name"
          className={styles.inputField}
        />
      </div>
    </OnboardingStep>
  );
};

export default NameStep;
