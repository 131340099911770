import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './LandingPage.module.css';
import appLogo from '../assets/logo.svg';
import calendarIcon from '../assets/icons/calendars.svg';
import coordinationIcon from '../assets/icons/coordination.svg';
import networkIcon from '../assets/icons/network.svg';
import connectionsIcon from '../assets/icons/connections.svg';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const goToBetaSignup = (): void => {
    navigate('/join-beta');
  };

  // Apply styles to the body on mount
  useEffect(() => {
    document.body.style.backgroundColor = 'white'; // Set background color

    // Optional: Cleanup styles on unmount
    return () => {
      document.body.style.backgroundColor = 'black';
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Parenting Is Easier Together</title>
      </Helmet>

      {/* Sticky Navbar */}
      <nav id="navbar" className={styles.navbar}>
        <div className={styles.navbarContent}>
          <div className={styles.logoContent}>
            <img src={appLogo} alt="Company Logo" className={styles.logo} />
          </div>
          <button className={styles.navButton} onClick={goToBetaSignup}>
            Join Beta
          </button>
        </div>
      </nav>

      {/* Hero Section */}
      <section id="hero" className={styles.hero}>
        <div id="hero-content" className={styles.heroContent}>
          <h1>Parenting Is Easier Together</h1>
          <p>Even the most organized parents need help.</p>
          <p>
            With simplified tracking from Plii and other parents filling in the
            gaps, you can stay on top of what’s important without extra hassle.
          </p>
          <button className={styles.heroButton} onClick={goToBetaSignup}>
            Join Plii Beta
          </button>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className={styles.features}>
        <div className={styles.featureCard}>
          <div className={styles.iconContainer}>
            <img
              src={calendarIcon}
              alt="Calendar Icon"
              className={styles.featureIcon}
            />
          </div>
          <h3>
            Simplify
            <br />
            Your Schedule
          </h3>
          <p>Plii makes managing your calendar easy.</p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.iconContainer}>
            <img
              src={coordinationIcon}
              alt="Coordination Icon"
              className={styles.featureIcon}
            />
          </div>
          <h3>
            Easy
            <br />
            Coordination
          </h3>
          <p>
            Plan with other parents without the usual back-and-forth and
            hesitation.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.iconContainer}>
            <img src={networkIcon} alt="Network Icon" className={styles.featureIcon} />
          </div>
          <h3>
            Fill in
            <br />
            the Gaps
          </h3>
          <p>
            Help each other by sharing and receiving events to fill in
            scheduling gaps.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.iconContainer}>
            <img
              src={connectionsIcon}
              alt="Connections Icon"
              className={styles.featureIcon}
            />
          </div>
          <h3>
            Safe
            <br />
            Connections
          </h3>
          <p>
            Connect with like-minded parents while controlling what you share.
          </p>
        </div>
      </section>

      {/* Email Signup Section */}
      <section id="signup" className={styles.signup}>
        <h2 className={styles.header}>Join Our Community</h2>
        <p className={styles.prompt}>Sign up to be the first to know when we launch.</p>
        <button className={styles.signupButton} onClick={goToBetaSignup}>
          Join Beta
        </button>
        <p className={styles.privacyNote}>
          *We respect your privacy and won't share your information.
        </p>
      </section>

      {/* Footer */}
      <footer>
        <div className={styles.footerLeft}>
          <p>&copy; 2024 plii LLC. All rights reserved.</p>
        </div>
        <div className={styles.footerRight}>
          <p>
            <a href="/privacy-policy">Privacy Policy</a> |{' '}
            <a href="/terms-and-conditions">Terms and Conditions</a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default LandingPage;
