import React, { useState, useEffect } from 'react';
import styles from './ChildInfoStep.module.css';
import OnboardingStep, { OnboardingContext } from './OnboardingStep';
import { useNavigate } from 'react-router-dom';
import DynamicTypeaheadSelect from '../input/DynamicTypeaheadSelect';
import ManualDateInput from '../input/ManualDateInput';

interface ChildInfo {
  name: string;
  gender: string;
  birthDate: string | undefined;
  schoolName: string;
  className: string;
}

interface SchoolInfo {
  label: string;
  value: string;
  zipcode: string;
  city: string;
}

interface ChildInfoStepProps {
  onboardingContext: OnboardingContext;
  childIndex: number;
  onNext: (childInfo: ChildInfo) => void;
  existingValues?: ChildInfo;
}

const ChildInfoStep: React.FC<ChildInfoStepProps> = ({ onboardingContext, childIndex, onNext, existingValues }) => {
  const navigate = useNavigate();
  const [childInfo, setChildInfo] = useState<ChildInfo>({
    name: existingValues?.name || '',
    gender: existingValues?.gender || '',
    birthDate: existingValues?.birthDate || undefined,
    schoolName: existingValues?.schoolName || '',
    className: existingValues?.className || '',
  });

  const [buttonEnabled, setButtonEnabled] = useState(false); // Tracks button state
  const [isTypeaheadFocused, setIsTypeaheadFocused] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState<SchoolInfo[]>([
    { value: 'The Montessori School at Flatiron', label: 'The Montessori School at Flatiron', city: 'New York', zipcode: '10010' },
  ]);

  useEffect(() => {
    setChildInfo({
      name: existingValues?.name || '',
      gender: existingValues?.gender || '',
      birthDate: existingValues?.birthDate || undefined,
      schoolName: existingValues?.schoolName || '',
      className: existingValues?.className || '',
    });
  }, [childIndex, existingValues]);

  // Update button state whenever count changes
  useEffect(() => {
    const allInfoFilled = childInfo.name != '' && childInfo.gender != '' && childInfo.birthDate != ''
    setButtonEnabled(allInfoFilled); // Ensure button is enabled only for valid counts
  }, [childInfo]);

  const ordinals = [
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
  ];

  const handleChange = (key: keyof ChildInfo, value: string) => {
    setChildInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validate = () => {
    if (!childInfo.name.trim()) {
      navigate(`?error=true&message=${encodeURIComponent('First name is required')}`);
      return false;
    }
    if (!childInfo.gender) {
      navigate(`?error=true&message=${encodeURIComponent('Gender is required')}`);
      return false;
    }
    if (!childInfo.birthDate) {
      navigate(`?error=true&message=${encodeURIComponent('Birthday is required')}`);
      return false;
    }
    return true;
  };

  const handleContinue = () => {
    if (validate()) {
      onNext(childInfo);
    }
  };

  const childOrdinal = ordinals[childIndex] || `${childIndex + 1}th`;

  const transformSchoolData = (rawData: any[]) => {
    return rawData.map((item, index) => ({
      value: item.name,
      label: item.name, // Main display text
      city: item.city, // Additional metadata
      zipcode: item.zipcode, // Additional metadata
    }));
  };

  const handleSchoolOptions = (options: SchoolInfo[]) => {
    setSchoolOptions(options);
  }

  return (
    <OnboardingStep
      onboardingContext={onboardingContext}
      title={`Tell us more about your ${childOrdinal} child! We will use this information to pull in available events from the school.`}
      subtitle=""
      buttonEnabled={buttonEnabled}
      onContinue={handleContinue}
    >
      {isTypeaheadFocused && <div className={styles.overlay}></div>}
      <div
        className={`${styles.childInfoContainer} ${isTypeaheadFocused ? styles.blurred : ''
          }`}
      >
        <div className={styles.inputContainer}>
          <input
            type="text"
            id="name"
            value={childInfo.name}
            onChange={(e) => handleChange('name', e.target.value)}
            placeholder="First Name"
            className={styles.inputField}
          />
        </div>
        <div className={styles.radioGroup}>
          <label className={`${styles.radioLabel} ${childInfo.gender === 'Male' ? styles.selected : ''}`}>
            <input
              className={styles.radioInput}
              type="radio"
              name="gender"
              value="Male"
              checked={childInfo.gender === 'Male'}
              onChange={(e) => handleChange('gender', e.target.value)}
            />
            Male
          </label>
          <label className={`${styles.radioLabel} ${childInfo.gender === 'Female' ? styles.selected : ''}`}>
            <input
              className={styles.radioInput}
              type="radio"
              name="gender"
              value="Female"
              checked={childInfo.gender === 'Female'}
              onChange={(e) => handleChange('gender', e.target.value)}
            />
            Female
          </label>
        </div>
        <div className={styles.calendarGroup}>
          <ManualDateInput key={childInfo.birthDate} onDateChange={(date) => handleChange('birthDate', date)} existingValue={childInfo.birthDate || ''} />
        </div>
        <div className={`${styles.inputContainer} ${isTypeaheadFocused ? styles.typeaheadModal : ''}`}>
          <DynamicTypeaheadSelect
            key={childInfo.schoolName}
            placeholder="School Name (optional)"
            preLoadOptions={schoolOptions}
            updateOptions={handleSchoolOptions}
            apiEndpoint="/api/typeahead/search/schools"
            transformFunction={transformSchoolData}
            onFocus={() => setIsTypeaheadFocused(true)}
            onBlur={() => setIsTypeaheadFocused(false)}
            value={childInfo.schoolName || ''}
            onChange={(value) => handleChange('schoolName', value)}
            label="School Name (Optional)"
            formatOption={(option) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>{option.label}</span>
                <span style={{ fontSize: '0.875rem', color: '#555' }}>
                  {option.city}, {option.zipcode}
                </span>
              </div>
            )}
          />
        </div>
      </div>
    </OnboardingStep >
  );
};

export default ChildInfoStep;
