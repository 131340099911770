import { Device } from "@capacitor/device";

export let API_BASE_URL = "https://plii.app"; // Default to production

export const configureApiBaseUrl = async () => {
  const info = await Device.getInfo();

  if (info.platform === "web") {
    if (window.location.hostname === "localhost") {
      API_BASE_URL = "http://localhost:8080";
    }
  } else if (info.platform === "ios" || info.platform === "android") {
    if (info.isVirtual) {
      // TODO: Replace this with an automated address
      API_BASE_URL = "http://192.168.87.22:8080"; // Running in simulator
      //
      // API_BASE_URL = "http://10.200.6.1:8080"; // Running in simulator
    }
  }

  console.log(`Configured API_BASE_URL: ${API_BASE_URL}`);
  return API_BASE_URL;
};
