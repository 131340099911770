import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
// @ts-ignore
import { CREATE_INTERACTION } from '../graphql/mutations/createInteraction';
// @ts-ignore
import { HANDLE_NEW_USER_MESSAGE } from '../graphql/mutations/handleNewUserMessage';
// @ts-ignore
import { HANDLE_USER_ACTION } from '../graphql/mutations/handleUserAction';
// @ts-ignore
import ChatWindow from '../components/ChatWindow';
// @ts-ignore
import MessageInput from '../components/MessageInput';
// @ts-ignore
import Sidebar from '../components/Sidebar';
// @ts-ignore
import ProfileBubble from '../components/ProfileBubble';
// @ts-ignore
import './HomePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { User } from '../types/User';
import { Message } from '../types/Message';
import { LOCAL_MODE } from '../config/local';
import { isNative } from '../platform/platform';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';

interface HomePageProps {
  user: User;
}

const HomePage: React.FC<HomePageProps> = ({ user }) => {
  const [interactionID, setInteractionID] = useState<string | null>(
    localStorage.getItem('interactionID')
  );
  const [messages, setMessages] = useState<Message[]>(() => {
    const savedMessages = localStorage.getItem('messages');
    if (savedMessages) {
      return JSON.parse(savedMessages);
    }

    if (!LOCAL_MODE) {
      return [];
    }
    // Mock messages for initial rendering
    return [
      {
        id: 1,
        content: 'Welcome to the chat! How can I assist you today?',
        sender: 'assistant',
        role: 'assistant',
      },
      {
        id: 2,
        content: 'Can you help me with integrating my calendar?',
        sender: 'user',
        role: 'user',
      },
      {
        id: 3,
        content: 'Sure! Do you want to connect your Google or Outlook calendar?',
        sender: 'assistant',
        role: 'assistant',
      },
    ];
  });
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [loading, setLoading] = useState<boolean>(false);
  const [resetting, setResetting] = useState<boolean>(false);

  const [createInteraction] = useMutation<{ createInteraction: { id: string; messages: Message[] } }>(
    CREATE_INTERACTION,
    {
      onCompleted: (data) => {
        const newInteraction = data.createInteraction;
        setInteractionID(newInteraction.id);
        setMessages((prevMessages) => [
          ...prevMessages,
          ...newInteraction.messages.map((msg, index) => ({
            id: prevMessages.length + index + 1,
            content: msg.content,
            role: msg.role === 'user' ? 'user' : 'assistant', // Ensure the sender type matches
            actionPayload: msg.actionPayload,
            actionTaken: false,
          } as Message)), // Explicitly cast to Message
        ]);
        setLoading(false);
        setResetting(false);
      },
      onError: (error) => {
        console.error('Failed to create interaction:', error);
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    // Enable native resizing for this page
    Keyboard.setResizeMode({ mode: KeyboardResize.Native });

    // Cleanup: Reset to default behavior when leaving this page
    return () => {
      Keyboard.setResizeMode({ mode: KeyboardResize.None }); // Or "body" depending on your global preference
    };
  }, []);

  const resetInteraction = () => {
    setResetting(true);
    setTimeout(() => {
      setMessages([]);
      setInteractionID(null);
      createInteraction();
    }, 1000);
  };

  const [handleNewUserMessage] = useMutation(HANDLE_NEW_USER_MESSAGE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.handleNewUserMessage?.message) {
        const newMessage = data.handleNewUserMessage.message;
        const archive = data.handleNewUserMessage.archive;
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: prevMessages.length + 1,
            content: newMessage.content,
            role: 'assistant',
            actionPayload: newMessage.actionPayload || null,
            actionTaken: false,
          },
        ]);
        if (archive) {
          resetInteraction();
        }
      }
      setLoading(false);
    },
    onError: (error) => {
      console.error('Failed to send message:', error);
      setLoading(false);
    },
  });

  const handleSendMessage = (messageContent: string) => {
    if (!interactionID) return;
    setLoading(true);

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: prevMessages.length + 1,
        content: messageContent,
        sender: 'user',
        role: 'user',
      },
    ]);

    handleNewUserMessage({
      variables: {
        interactionID,
        message: { content: messageContent },
      },
    });
  };

  const [handleUserAction] = useMutation(HANDLE_USER_ACTION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const newMessage = data.handleUserAction.message;
      const archive = data.handleUserAction.archive;

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages.length + 1,
          content: newMessage.content,
          role: 'assistant',
          actionPayload: newMessage.actionPayload,
          actionTaken: false,
        },
      ]);
      setLoading(false);
      if (archive) resetInteraction();
    },
    onError: (error) => {
      console.error('Failed to handle action:', error);
      setLoading(false);
    },
  });

  const handleActionClick = (
    taskID: string,
    actionType: string,
    choice: string,
    multiSelectOptions?: any
  ) => {
    setLoading(true);
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.actionPayload?.taskID === taskID
          ? { ...msg, actionTaken: true }
          : msg
      )
    );
    if (actionType === 'CALENDAR_INTEGRATION') {
      localStorage.setItem('pendingTaskID', taskID);
      localStorage.setItem('pendingChoice', choice);
      localStorage.setItem('interactionID', interactionID || '');
      localStorage.setItem('messages', JSON.stringify(messages));
      window.location.href = '/api/auth/calendar';
    } else {
      handleUserAction({
        variables: {
          interactionID,
          actionInput: {
            taskID,
            choice,
            multiSelectOptions,
          },
        },
        onCompleted: () => setLoading(false),
      });
    }
  };

  useEffect(() => {
    const initializeInteraction = async () => {
      const savedInteractionID = localStorage.getItem('interactionID');
      const savedMessages = localStorage.getItem('messages');

      if (savedInteractionID && savedMessages) {
        setInteractionID(savedInteractionID);
        setMessages(JSON.parse(savedMessages));
      } else {
        setLoading(true);
        await createInteraction();
      }
    };

    initializeInteraction();

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [createInteraction]);

  useEffect(() => {
    // Check if the platform is iOS
    if (isNative()) {
      const rootElement = document.getElementById('root');
      if (rootElement) {
        rootElement.classList.add('iosTopPadding');
      }
    }

    // Cleanup: Remove the class when unmounting
    return () => {
      const rootElement = document.getElementById('root');
      if (rootElement) {
        rootElement.classList.remove('iosTopPadding');
      }
    };
  }, []);

  return (
    <div className={`home-container ${sidebarOpen ? 'sidebar-open' : ''}`}>
      <div className={`navbar ${isNative() ? 'iosTopMove' : ''}`}>
        {!sidebarOpen && isMobile && (
          <button className="compose-button" onClick={resetInteraction}><FontAwesomeIcon icon={faPen} /></button>
        )}
        <div className="hamburger-menu" onClick={() => setSidebarOpen((prev) => !prev)}>☰</div>
        {!isMobile && <ProfileBubble profilePictureUrl={user.profilePictureUrl} />}
      </div>
      {sidebarOpen && isMobile && <div className="overlay" onClick={() => setSidebarOpen(false)} />}
      <Sidebar
        isOpen={sidebarOpen}
        closeSidebar={() => setSidebarOpen(false)}
        profilePictureUrl={user.profilePictureUrl}
        user={user}
        isMobile={isMobile}
        resetInteraction={resetInteraction}
      />
      <div className={`right-chat-container ${resetting ? 'fade-out' : 'fade-in'}`}>
        <ChatWindow messages={messages} showLoadingIndicator={loading} onAction={handleActionClick} />
        <MessageInput onSend={handleSendMessage} disabled={loading || resetting || !interactionID} />
      </div>
    </div>
  );
};

export default HomePage;