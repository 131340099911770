import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';
import './ChatWindow.css';
import React, { useRef, useEffect, useState } from 'react';

const ChatWindow = ({ messages, showLoadingIndicator, onAction }) => {
  const messageEndRef = useRef(null);

  // Track selected high-level choice and multi-select sub-options
  const [selectedChoice, setSelectedChoice] = useState({});
  const [selectedMultiOptions, setSelectedMultiOptions] = useState({});

  const handleChoiceSelect = (messageIndex, choice) => {
    setSelectedChoice({ ...selectedChoice, [messageIndex]: choice });
    setSelectedMultiOptions({ ...selectedMultiOptions, [messageIndex]: [] }); // Reset multi-options for this choice
  };

  const handleMultiSelectOption = (messageIndex, optionChoice) => {
    setSelectedMultiOptions(prev => {
      const currentOptions = prev[messageIndex] || [];
      const updatedOptions = currentOptions.includes(optionChoice)
        ? currentOptions.filter(choice => choice !== optionChoice)
        : [...currentOptions, optionChoice];
      return { ...prev, [messageIndex]: updatedOptions };
    });
  };

  const handleSubmit = (taskID, actionType, choiceObject, multiOptions) => {
    // Pass both high-level choice and multi-selected options (if any)
    onAction(taskID, actionType, choiceObject.choice, multiOptions);
  };

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="chat-window">
      {messages.map((message, index) => (
        <div key={index} className={`message ${message.role}`}>
          <ReactMarkdown rehypePlugins={[rehypeSanitize]}>
            {message.content}
          </ReactMarkdown>

          {message.actionPayload && (
            <div className="action-buttons">
              {/* Render main choices with highlight for the selected choice */}
              {message.actionPayload.choices.map((choice, idx) => (
                <button
                  key={idx}
                  onClick={() => handleChoiceSelect(index, choice)}
                  className={selectedChoice[index]?.choice === choice.choice ? 'selected-button' : ''}
                  disabled={message.actionTaken || selectedChoice[index]?.choice === choice.choice}
                >
                  {choice.label}
                </button>
              ))}

              {/* If the selected choice has multi-select options, render checkboxes */}
              {selectedChoice[index] && message.actionPayload.choices.find(
                c => c.choice === selectedChoice[index].choice && c.multiSelectOptions
              )?.multiSelectOptions && (
                  <div className="multi-select-options">
                    {message.actionPayload.choices
                      .find(c => c.choice === selectedChoice[index].choice)
                      .multiSelectOptions.map(option => (
                        <label key={option.choice}>
                          <input
                            type="checkbox"
                            checked={(selectedMultiOptions[index] || []).includes(option.choice)}
                            onChange={() => handleMultiSelectOption(index, option.choice)}
                          />
                          {option.label}
                        </label>
                      ))}
                  </div>
                )}

              {/* Submit button for selected choice and multi-options */}
              {selectedChoice[index] && (
                <button
                  className="submit-button"
                  onClick={() =>
                    handleSubmit(
                      message.actionPayload.taskID,
                      message.actionPayload.actionType,
                      selectedChoice[index],
                      selectedMultiOptions[index] || []
                    )
                  }
                  disabled={
                    message.actionTaken ||
                    (selectedChoice[index]?.multiSelectOptions &&
                      !(selectedMultiOptions[index] && selectedMultiOptions[index].length > 0))
                  }
                >
                  Submit
                </button>
              )}
            </div>
          )}
        </div>
      ))}

      {showLoadingIndicator && (
        <div className="loading-indicator">Waiting for response...</div>
      )}

      <div ref={messageEndRef}></div>
    </div>
  );
};

export default ChatWindow;
