import React, { useState, useEffect } from 'react';
import styles from './ManualDateInput.module.css';

interface ManualDateInputProps {
    onDateChange: (date: string) => void;
    existingValue?: string; // Prepopulate fields in the format YYYY-MM-DD
}

const ManualDateInput: React.FC<ManualDateInputProps> = ({ onDateChange, existingValue }) => {
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState('');

    const currentYear = new Date().getFullYear();

    // Populate fields from existingValue
    useEffect(() => {
        if (existingValue) {
            const [existingYear, existingMonth, existingDay] = existingValue.split('-');
            setYear(existingYear || '');
            setMonth(existingMonth || '');
            setDay(existingDay || '');
        }
    }, [existingValue]);

    const handleChange = (type: string, value: string) => {
        // Restrict to numbers only
        const numericValue = value.replace(/\D/g, '');

        let updatedMonth = month;
        let updatedDay = day;
        let updatedYear = year;

        if (type === 'month') {
            if (numericValue.length <= 2 && +numericValue <= 12) {
                updatedMonth = numericValue;
                setMonth(updatedMonth);
            }
        } else if (type === 'day') {
            if (numericValue.length <= 2 && +numericValue <= 31) {
                updatedDay = numericValue;
                setDay(updatedDay);
            }
        } else if (type === 'year') {
            if (numericValue.length <= 4) {
                // Only validate year when it reaches 4 digits
                if (numericValue.length === 4) {
                    const numericYear = +numericValue;
                    if (numericYear <= currentYear && numericYear >= 1900) {
                        updatedYear = numericValue;
                        setYear(updatedYear);
                    } else {
                        alert('Please enter a valid year between 1900 and the current year.');
                        return; // Prevent invalid year from being set
                    }
                } else {
                    updatedYear = numericValue;
                    setYear(updatedYear);
                }
            }
        }

        // Pass complete date back if all fields are filled
        if (updatedMonth && updatedDay && updatedYear && updatedYear.length == 4) {
            onDateChange(`${updatedYear}-${updatedMonth.padStart(2, '0')}-${updatedDay.padStart(2, '0')}`);
        }
    };

    return (
        <div className={styles.dateInputContainer}>
            <input
                className={styles.dateField}
                type="text"
                placeholder="MM"
                value={month}
                onChange={(e) => handleChange('month', e.target.value)}
                maxLength={2}
            />
            <input
                className={styles.dateField}
                type="text"
                placeholder="DD"
                value={day}
                onChange={(e) => handleChange('day', e.target.value)}
                maxLength={2}
            />
            <input
                className={styles.dateField}
                type="text"
                placeholder="YYYY"
                value={year}
                onChange={(e) => handleChange('year', e.target.value)}
                maxLength={4}
            />
        </div>
    );
};

export default ManualDateInput;
