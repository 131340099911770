import React from 'react';
import styles from './ActionButton.module.css';
import googleLogo from '../../assets/google-icon.svg';
import appleLogo from '../../assets/apple-icon-black.svg';

interface ActionButtonProps {
    onClick: () => void; // Function to execute on click
    children: React.ReactNode; // Button content (e.g., "Log in", "Submit")
    disabled?: boolean; // Optional: Disable the button
    className?: string;
    iconType?: 'Google' | 'Apple' | undefined;
}

const ActionButton: React.FC<ActionButtonProps> = ({ onClick, children, disabled = false, iconType = undefined, className = '' }) => {
    const renderIcon = (iconType?: 'Google' | 'Apple') => {
        switch (iconType) {
            case 'Google':
                return <img src={googleLogo} alt="Google Icon" className={styles.icon} />;
            case 'Apple':
                return <img src={appleLogo} alt="Apple Icon" className={styles.appleIcon} />;
            default:
                return null;
        }
    };

    return (
        <button
            type="button"
            className={`${styles.actionButton} ${disabled ? styles.disabled : ''} ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {iconType && renderIcon(iconType)}
            {children}
        </button>
    );
};

export default ActionButton;
