import { Preferences } from '@capacitor/preferences';
import { generateRandomString, sha256, base64UrlEncode } from '../utils/crypto';

export const generatePKCE = async () => {
  const codeVerifier = generateRandomString(128); // Generate the PKCE code verifier
  const hashBuffer = await sha256(codeVerifier); // Hash the code verifier
  const codeChallenge = base64UrlEncode(hashBuffer); // Convert the hash to a base64 URL-safe string
  return { codeVerifier, codeChallenge };
};

export const storeVerifier = async (codeVerifier: string) => {
    await Preferences.set({
        key: 'codeVerifier',
        value: codeVerifier,
      });
}

export const fetchVerifier = async (): Promise<string> => {
    const { value } = await Preferences.get({key: 'codeVerifier'});
    return value === null ? '' : value;
}