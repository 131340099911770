import React, { useEffect, useState } from 'react';
import styles from './ExistingLoginScreen.module.css';
import googleLogo from '../../assets/google-icon.svg';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/Layout';
import ActionButton from '../button/ActionButton';
import { AUTH_TYPE, handleLoginWithGoogle } from '../../google/loginHandler';

const LoginScreen: React.FC = () => {
  const navigate = useNavigate();
  const handleForgotPassword = () => {
    console.log('Redirecting to forgot password flow...');
  };

  const handleLogin = () => {
    console.log('Handling user login...');
  };

  const handleBack = () => {
    // Handle existing user login logic
    navigate('/auth/login');
  };


  return (
    <Layout navbarConfig={{ title: "Log in", onBack: handleBack }}>
      <form className={styles.loginForm}>
        <input
          type="text"
          placeholder="Email address/phone number"
          className={styles.loginInput}
        />
        <input type="password" placeholder="Password" className={styles.loginInput} />
        <button
          type="button"
          className={styles.forgotPasswordLink}
          onClick={handleForgotPassword}
        >
          Forgot password?
        </button>
        <ActionButton onClick={handleLogin} className={styles.formLoginButton}>
          Log in
        </ActionButton>
      </form>
      <div className={styles.loginDivider}>or</div>
      <button
        type="button"
        className={styles.googleLoginButton}
        onClick={() => handleLoginWithGoogle(AUTH_TYPE.LOGIN)}
      >
        <img
          src={googleLogo}
          alt="Google Icon"
          className={styles.googleIcon}
        />
        Log in with Google
      </button>
    </Layout>
  );
};

export default LoginScreen;
