import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import DigestItem from "./DigestItem";
import styles from "./DigestStackedView.module.css";
import { Digest, SectionType, DigestSection as DigestSectionType } from "../../types/Digest";
import DigestCard from "./DigestCard";

interface DigestStackedViewProps {
    digest: Digest;
    triggerPrevSignal: number;
}

const DigestStackedView: React.FC<DigestStackedViewProps> = ({ digest, triggerPrevSignal }) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0); // Start with the top card
    const [offsetX, setOffsetX] = useState<number>(0); // Track swipe offset in real-time
    const [isDragging, setIsDragging] = useState<boolean>(false); // Track if the user is actively swiping
    const [rotation, setRotation] = useState<number>(0); // Track rotation during swipe

    // Listen for changes in the triggerPrevSignal
    useEffect(() => {
        if (triggerPrevSignal > 0) {
            setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        }
    }, [triggerPrevSignal]); // Runs whenever the signal changes

    const stackedViewSection: DigestSectionType = digest.sections.find(
        section => {
            return section.sectionType == SectionType.PRIORITY;
        }
    ) || { sectionType: SectionType.PRIORITY, items: [] };

    const numItems = stackedViewSection.items.length;
    const multiplier = 0.3; // Slow down the swipe movement

    const handleSwipeStart = () => {
        setIsDragging(true); // Start tracking swipe
    };

    const handleSwipeMove = (deltaX: number) => {
        if (!isDragging) return;

        // Limit offset and apply rotation
        const maxOffset = 200;
        const newOffsetX = Math.max(-maxOffset, Math.min(maxOffset, deltaX * multiplier));
        setOffsetX(newOffsetX);
        setRotation((newOffsetX / maxOffset) * 15); // Rotate up to 15 degrees
    };

    const handleSwipeEnd = (deltaX: number, velocity: number) => {
        setIsDragging(false); // Stop tracking swipe
        setRotation(0); // Reset rotation

        const threshold = 100; // Minimum swipe distance to trigger a card change
        const isSwipingLeft = deltaX < -threshold;
        const isSwipingRight = deltaX > threshold;

        if (isSwipingLeft || isSwipingRight) {
            // Animate the current card out of view
            setOffsetX(deltaX < 0 ? -300 : 300); // Move the card completely offscreen
            setTimeout(() => {
                setOffsetX(0); // Reset offset for the next card
                setCurrentIndex((prev) => (prev + 1) % numItems); // Go to the next card
            }, 300); // Wait for the animation to complete
        } else {
            // Snap back if the swipe is not strong enough
            setOffsetX(0);
        }
    };

    const swipeHandlers = useSwipeable({
        onSwiping: (eventData) => handleSwipeMove(eventData.deltaX), // Track real-time movement
        onSwiped: (eventData) => handleSwipeEnd(eventData.deltaX, eventData.velocity), // Handle swipe release
        onSwipeStart: () => handleSwipeStart(),
        preventScrollOnSwipe: true,
    });

    return (
        <div className={styles.digestStackedView} {...swipeHandlers}>
            {/* Top card */}
            {stackedViewSection.items.slice(currentIndex, currentIndex + 2).map((item, index) => {
                const isTopCard = index === 0; // The first card in slice is the top card
                const zIndex = 2 - index; // Top card has higher z-index

                return (
                    <div
                        key={currentIndex + index}
                        className={`${styles.card} ${isTopCard ? styles.topCard : ""}`}
                        style={{
                            zIndex,
                            transform: isTopCard
                                ? `translateX(${offsetX}px) rotate(${rotation}deg)`
                                : `translateY(10px) scale(0.95)`, // Slightly behind the top card
                            opacity: isTopCard ? 1 : 0.95, // Full opacity for the top card
                            transition: isDragging
                                ? "none"
                                : "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
                        }}
                    >
                        <DigestCard item={item} />
                    </div>
                );
            })}
        </div>
    );
};

export default DigestStackedView;
