import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import appLogo from '../../assets/primary-logo.svg';
import googleLogo from '../../assets/google-icon.svg';
import { AUTH_TYPE, handleLoginWithGoogle } from '../../google/loginHandler';

function Login() {
  const navigate = useNavigate();
  const handleSignUpWithEmail = () => {
    // Handle email signup logic
    console.log('Redirecting to email signup page...');
  };

  const handleLogIn = () => {
    // Handle existing user login logic
    navigate('/auth/login/existing');
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.logoContainer}>
        <img src={appLogo} alt="Plii Logo" className={styles.logo} />
        <h2 className={styles.tagline}>Parenting Is Easier Together</h2>
      </div>
      <div className={styles.termsContainer}>
        <p className={styles.termsText}>
          By signing up, you agree to Plii's <a href="/terms-and-conditions">Terms & Conditions</a> and <a href="/privacy-policy">Privacy Policy</a>.
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.signupEmailButton} onClick={handleSignUpWithEmail}>
          Sign up with email
        </button>
        <button className={styles.signupGoogleButton} onClick={() => handleLoginWithGoogle(AUTH_TYPE.LOGIN)}>
          <img src={googleLogo} alt="Google Icon" className={styles.googleIcon} />
          Sign up with Google
        </button>
        <button className={styles.loginButton} onClick={handleLogIn}>
          Log in
        </button>
      </div>
    </div>
  );
}

export default Login;
