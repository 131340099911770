import React, { useState, useEffect } from 'react';
import OnboardingStep, { OnboardingContext } from './OnboardingStep';
import minusIcon from '../../assets/counter/minus.svg';
import plusIcon from '../../assets/counter/plus.svg';
import styles from './KidsCountStep.module.css';

interface KidsCountStepProps {
  onboardingContext: OnboardingContext;
  onNext: (kidsCount: number) => void;
}

const KidsCountStep: React.FC<KidsCountStepProps> = ({ onboardingContext, onNext }) => {
  const [count, setCount] = useState(1);
  const [buttonEnabled, setButtonEnabled] = useState(true); // Tracks button state

  // Update button state whenever count changes
  useEffect(() => {
    setButtonEnabled(count >= 0); // Ensure button is enabled only for valid counts
  }, [count]);

  const handleDecrement = () => {
    setButtonEnabled(false); // Temporarily disable button during state update
    setCount((prev) => Math.max(0, prev - 1));
  };

  const handleIncrement = () => {
    setButtonEnabled(false); // Temporarily disable button during state update
    setCount((prev) => prev + 1);
  };

  const handleContinue = () => {
    onNext(count);
  };

  return (
    <OnboardingStep
      onboardingContext={onboardingContext}
      title="How many kids do you have?"
      subtitle="We will use this information to personalize your schedule coordination."
      buttonEnabled={buttonEnabled}
      onContinue={handleContinue}
    >
      <div className={styles.counterContainer}>
        <button
          onClick={handleDecrement}
          className={styles.counterButton}
        >
          <img src={minusIcon} alt="Minus" className={styles.icon} />
        </button>
        <span className={styles.counterValue}>{count}</span>
        <button
          onClick={handleIncrement}
          className={styles.counterButton}
        >
          <img src={plusIcon} alt="Plus" className={styles.icon} />
        </button>
      </div>
    </OnboardingStep>
  );
};

export default KidsCountStep;
