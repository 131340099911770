import { gql } from '@apollo/client';

export const GET_ANALYSIS_STATUS = gql`
  query GetAnalysisStatus($taskID: String!) {
  getAnalysisStatus(taskID: $taskID) {
    taskID
    status
    createdAt
    completedAt
  }
}
`;
