// src/utils/cryptoUtils.ts

/**
 * Generate a random string for use as a PKCE code verifier
 * @param length The desired length of the string
 * @returns A random string
 */
export const generateRandomString = (length: number): string => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let randomString = '';
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    for (let i = 0; i < array.length; i++) {
      randomString += charset[array[i] % charset.length];
    }
    return randomString;
  };
  
  /**
   * Hash a string using SHA-256
   * @param input The string to hash
   * @returns The hash as an ArrayBuffer
   */
  export const sha256 = async (input: string): Promise<ArrayBuffer> => {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    return window.crypto.subtle.digest('SHA-256', data);
  };
  
  /**
   * Convert an ArrayBuffer to a base64 URL-encoded string
   * @param buffer The ArrayBuffer to encode
   * @returns The base64 URL-encoded string
   */
  export const base64UrlEncode = (buffer: ArrayBuffer): string => {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64 = btoa(binary);
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  };
  