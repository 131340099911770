import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { configureApiBaseUrl } from '../config/api';
import { AUTH_TYPE } from '../google/loginHandler';

export async function exchangeGoogleTokensForAccesTokens(authType: AUTH_TYPE, idToken: string, accessToken: string, refreshToken?: string): Promise<string> {
  const payload: Record<string, string> = { id_token: idToken, access_token: accessToken };
  if (refreshToken) {
    payload.refresh_token = refreshToken;
  }
  payload.type = authType.toLowerCase();

  const API_BASE_URL = await configureApiBaseUrl();

  const response = await fetch(`${API_BASE_URL}/api/auth/google/exchange`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });

  console.log('in request send');
  if (!response.ok) {
    throw new Error('Login failed');
  }

  const { access_token, refresh_token, email } = await response.json();

  await SecureStoragePlugin.set({ key: 'access_token', value: access_token });
  await SecureStoragePlugin.set({ key: 'refresh_token', value: refresh_token });

  return email;
}