import { Device } from "@capacitor/device";

export let REDIRECT_DOMAIN = "https://plii.app"; // Default to production

export const configureRedirectBaseUrl = async () => {
  const info = await Device.getInfo();

  if (info.platform === "web") {
    if (window.location.hostname === "localhost") {
        REDIRECT_DOMAIN = "http://localhost:3000";
    }
  }

  console.log(`Configured API_BASE_URL: ${REDIRECT_DOMAIN}`);
  return REDIRECT_DOMAIN;
};

export const getGoogleConfig = () => {
    return {
      CLIENT_ID: '691423973656-sr4puicb1gr6a8p6bj0i8jbfo4vjrat4.apps.googleusercontent.com',
      REDIRECT_URI: REDIRECT_DOMAIN + '/auth/google-callback',
    };
};
