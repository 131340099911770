import React, { useState } from "react";
import styles from "./DigestListView.module.css";
import { Digest, SectionType, DigestSection as DigestSectionType, AttentionLevelType } from "../../types/Digest";
import DigestCard from "./DigestCard";

interface DigestListViewProps {
    digest: Digest;
}

const DigestListView: React.FC<DigestListViewProps> = ({ digest }) => {
    // Find the EVENTS section
    const eventsSection: DigestSectionType = digest.sections.find(
        section => section.sectionType === SectionType.EVENTS
    ) || { sectionType: SectionType.EVENTS, items: [] };

    // State for filtering
    const [filter, setFilter] = useState<AttentionLevelType | null>(null);

    // Filtered items based on the selected filter
    const filteredItems = filter
        ? eventsSection.items.filter(item => item.attentionLevel === filter)
        : eventsSection.items;

    return (
        <div className={styles.digestListView}>
            {/* Filter Buttons */}
            <div className={styles.filterContainer}>
                <button
                    className={`${styles.filterButton} ${filter === null ? styles.activeFilter : ""}`}
                    onClick={() => setFilter(null)}
                >
                    All
                </button>
                <button
                    className={`${styles.filterButton} ${filter === AttentionLevelType.INFORMATIONAL ? styles.activeFilter : ""}`}
                    onClick={() => setFilter(AttentionLevelType.INFORMATIONAL)}
                >
                    Regular
                </button>
                <button
                    className={`${styles.filterButton} ${filter === AttentionLevelType.ACTION_REQUIRED ? styles.activeFilter : ""}`}
                    onClick={() => setFilter(AttentionLevelType.ACTION_REQUIRED)}
                >
                    Action Required
                </button>
            </div>

            <div className={styles.digestListViewContent}>
                {filteredItems.map((item, index) => (
                    <div key={index} className={styles.listItem}>
                        <DigestCard item={item} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DigestListView;
