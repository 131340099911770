import React, { useState } from 'react';
import Layout from '../layout/Layout';
import DigestStackedView from '../digest/DigestStackedView';
import { Digest } from '../../types/Digest';
import { DigestViewFooter } from './DigestViewFooter';
import styles from './DigestView.module.css'; // Add custom styles if needed
import DigestListView from './DigestListView';

interface DigestViewProps {
    digest: Digest;
    onBack?: () => void; // Optional back button handler for the navbar
    title?: string; // Title for the navbar
    onSwitchToListView: () => void; // Callback for switching to list view
}

const DigestView: React.FC<DigestViewProps> = ({ digest, onBack, title = "Digest", onSwitchToListView }) => {
    const [isListView, setIsListView] = useState(false);

    // State to signal the "prev card" action
    const [triggerPrevSignal, setTriggerPrevSignal] = useState(0);

    // Toggle between list and stacked views
    const handleSwitchView = () => {
        setIsListView((prev) => !prev);
    };

    // Handler for "Prev" button, passed down to DigestStackedView
    const handlePrevCard = () => {
        setTriggerPrevSignal((prev) => prev + 1); // Increment signal value
    };

    return (
        <Layout
            navbarConfig={{ title, onBack }}
            overflow="hidden" // Prevent overflow for the carousel
            footer={(
                <DigestViewFooter
                    onPrevCard={handlePrevCard}
                    onSwitchView={handleSwitchView}
                    isListView={isListView}
                />
            )}
        >
            <div className={styles.digestContainer}>
                {isListView ? (
                    <DigestListView digest={digest} />
                ) : (
                    <DigestStackedView
                        digest={digest}
                        triggerPrevSignal={triggerPrevSignal} // Pass signal to DigestStackedView
                    />
                )}
            </div>
        </Layout>
    );
};

export default DigestView;
