import { Preferences } from '@capacitor/preferences';
import { isMobileWeb } from '../platform/platform';

/**
 * Save introduction completion status.
 */
export const setAppIntroductionComplete = async (): Promise<void> => {
  await Preferences.set({ key: 'appIntroductionComplete', value: 'true' });
};

/**
 * Check if introduction is complete.
 * @returns {Promise<boolean>} True if onboarding is complete, false otherwise.
 */
export const isAppIntroductionComplete = async (): Promise<boolean> => {
  if (isMobileWeb()) {
    return true;
  }
  const { value } = await Preferences.get({ key: 'appIntroductionComplete' });
  return value === 'true';
};

/**
 * Clear introduction completion status.
 */
export const clearIntroductionStatus = async (): Promise<void> => {
  await Preferences.remove({ key: 'appIntroductionComplete' });
};
