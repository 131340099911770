import React, { useState, useEffect } from 'react';
import styles from './CalendarSync.module.css';
import { isIOS } from '../../platform/platform';
import { AdditionalButton } from '../onboarding/OnboardingStep';
import googleLogo from '../../assets/google-icon.svg';
import appleLogo from '../../assets/apple-icon-white.svg';

export interface SyncedAccount {
    provider: 'Google' | 'Apple';
    identifier: string;
    label?: string;
}

interface CalendarSyncProps {
    existingConnections: SyncedAccount[]; // Prefetched accounts
    onAccountAdd: (provider: 'Google' | 'Apple') => Promise<void>; // Handles adding an account
    onAccountRemove: (identifier: string) => Promise<void>; // Handles removing an account
    setAdditionalButtons?: (buttons: AdditionalButton[]) => void; // Create buttons for higher-level components
    loading: boolean; // New prop for loading state
    error?: Error | undefined; // New prop for error state
}

const CalendarSync: React.FC<CalendarSyncProps> = ({
    existingConnections,
    onAccountAdd,
    onAccountRemove,
    setAdditionalButtons,
    loading,
    error = undefined,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const renderIcon = (iconType?: 'Google' | 'Apple') => {
        switch (iconType) {
            case 'Google':
                return <img src={googleLogo} alt="Google Icon" className={styles.icon} />;
            case 'Apple':
                return <img src={appleLogo} alt="Apple Icon" className={styles.appleIcon} />;
            default:
                return null;
        }
    };

    // Handle adding an account
    const handleAddAccount = async (provider: 'Google' | 'Apple') => {
        try {
            setIsLoading(true);
            await onAccountAdd(provider); // Delegates to parent-provided logic
        } catch (error) {
            console.error(`Failed to add ${provider} account:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    // Handle removing an account
    const handleRemoveAccount = async (identifier: string) => {
        try {
            setIsLoading(true);
            await onAccountRemove(identifier); // Delegates to parent-provided logic
        } catch (error) {
            console.error(`Failed to remove account:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    // Dynamically set additional buttons
    useEffect(() => {
        if (setAdditionalButtons) {
            let buttonsList: AdditionalButton[] = [{
                label: 'Add Google Account',
                onClick: () => handleAddAccount('Google'),
                type: 'primary',
                iconType: 'Google',
            }];
            if (isIOS()) {
                buttonsList = buttonsList.concat([{
                    label: 'Add Device',
                    onClick: () => handleAddAccount('Apple'),
                    type: 'primary',
                    iconType: 'Apple',
                }]);
            }
            setAdditionalButtons(buttonsList);
        }
    }, [setAdditionalButtons]);

    return (
        <div className={styles.calendarSyncContainer}>
            <div className={styles.syncedAccounts}>
                {isLoading || loading ? (
                    <p>Loading accounts...</p>
                ) : existingConnections.length > 0 ? (
                    existingConnections.map((account, index) => (
                        <div key={index} className={styles.accountRow}>
                            {renderIcon(account.provider)}
                            <span className={styles.accountEmail}>
                                {account.label ? account.label : account.identifier}
                            </span>
                            <button
                                className={styles.removeButton}
                                onClick={() => handleRemoveAccount(account.identifier)}
                                disabled={isLoading}
                            >
                                Remove
                            </button>
                        </div>
                    ))
                ) : (
                    <p>No accounts synced yet.</p>
                )}
            </div>
        </div>
    );
};

export default CalendarSync;
