import React from 'react';
import styles from './OnboardingStep.module.css';
import Layout from '../layout/Layout';
import ActionButton from '../button/ActionButton';
import SecondaryButton from '../button/SecondaryButton';

export interface OnboardingContext {
  stepNumber: number;
  totalSteps: number;
  onBack?: () => void;
}

interface OnboardingStepProps {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  onboardingContext: OnboardingContext;
  buttonEnabled: boolean;
  onContinue: () => void;
  onSkip?: () => void;
  additionalButtons?: AdditionalButton[]; // New prop for additional callbacks
}

export interface AdditionalButton {
  label: string;
  onClick: () => void;
  type: 'primary' | 'secondary'; // Determine the button style
  iconType?: 'Google' | 'Apple';
}

const OnboardingStep: React.FC<OnboardingStepProps> = ({
  title,
  subtitle,
  children,
  onboardingContext,
  buttonEnabled,
  onContinue,
  onSkip,
  additionalButtons = [],
}) => {
  const actionButton =
    <ActionButton onClick={onContinue} disabled={!buttonEnabled}>
      Continue
    </ActionButton>;

  const skipButton =
    onSkip ?
      <SecondaryButton onClick={onSkip}>
        Skip for now
      </SecondaryButton>
      : <div />;

  const additionalButtonElements = additionalButtons.map((button, index) =>
    button.type === 'primary' ? (
      <ActionButton key={index} onClick={button.onClick} iconType={button.iconType}>
        {button.label}
      </ActionButton>
    ) : (
      <SecondaryButton key={index} onClick={button.onClick}>
        {button.label}
      </SecondaryButton>
    )
  );

  const footer = (
    <>
      {additionalButtonElements}
      {actionButton}
      {skipButton}
    </>
  );

  return (
    <Layout navbarConfig={{ title: "Sign Up", onBack: onboardingContext.onBack }} footer={footer}>
      <div className={styles.onboardingStepContainer}>
        <div className={styles.onboardingStepContentGroup}>
          <div className={styles.onboardingStepProgress}>
            {Array.from({ length: onboardingContext.totalSteps }).map((_, index) => (
              <span
                key={index}
                className={`${index + 1 === onboardingContext.stepNumber ? styles.activeDot : styles.dot} ${index + 1 === onboardingContext.stepNumber && index === 0 ? styles.firstDot : ''}`}
              >
                {index + 1 === onboardingContext.stepNumber ? index + 1 : ''}
              </span>
            ))}

          </div>
          <div className={styles.onboardingStepTitle}>{title}</div>
          <div className={styles.onboardingStepSubTitle}>{subtitle}</div>
          <div className={styles.onboardingStepContent}>{children}</div>
        </div>
      </div>
    </Layout>
  );
};

export default OnboardingStep;
