export interface UserData {
    firstName: string;
    lastName: string;
    kidsCount: number;
    profilePictureUrl: string;
    children: ChildInfo[]; // Array to hold information for each child
    partnerEmail: string,
}

export interface ChildInfo {
    name: string;
    gender: string;
    birthDate: string | undefined;
    schoolName: string;
    className: string;
}

export enum OnboardingSteps {
    CALENDAR_SYNC = "CALENDAR_SYNC",
    NAME = "NAME",
    PARTNER_EMAIL = "PARTNER_EMAIL",
    KIDS_COUNT = "KIDS_COUNT",
    CHILD_INFO = "CHILD_INFO",
    PROFILE_PICTURE = "PROFILE_PICTURE",
}