import { gql } from '@apollo/client';
import { USER_FIELDS } from '../fragments/User'; // Adjust the path as needed

export const SAVE_USER_PROFILE = gql`
  mutation SaveUserProfile($input: UserProfileInput!) {
    saveUserProfile(input: $input) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;