import { gql } from '@apollo/client';

export const GENERATE_DIGEST = gql`
  query GenerateDigest {
  generateDigest {
    sections {
      sectionTitle
      sectionDescription
      sectionType
      items {
        itemType
        additionalContext
        attentionLevel
        itemHeader {
          topTitle
          mainTitle
          description
        }
        content {
          ... on Event {
            eventID
            title
            startDate
            endDate
            description
          }
          ... on GroupedEvent {
            date
            mainEvents {
              eventID
              title
              owner {
                userID
                userSummary {
                    profilePictureUrl
                }
              }
              startDate
              endDate
            }
            relatedEvents {
              eventID
              title
              owner {
                userID
                userSummary {
                    profilePictureUrl
                }
              }
              startDate
              endDate
            }
            otherEvents {
              eventID
              title
              owner {
                userID
                userSummary {
                    profilePictureUrl
                }
              }
              startDate
              endDate
            }
          }
        }
      }
    }
  }
}
`;
