import { gql } from '@apollo/client';

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    email
    firstName
    lastName
    profilePictureUrl
    spouseEmail
    spouse {
      email
      firstName
      lastName
      profilePictureUrl
    }
    onboardingStatus {
      completed
    }
  }
`;
