import { gql } from '@apollo/client';
import { USER_FIELDS } from '../fragments/User'; // Adjust the path as needed

export const SAVE_USER_PROFILE_PICTURE = gql`
  mutation SaveUserProfilePicture($profilePictureURLInput: String!) {
    saveUserProfilePicture(input: $profilePictureURLInput) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;