import React from 'react';
import styles from './DigestViewFooter.module.css';
import listViewIcon from '../../assets/icons/listview.svg';
import stackViewIcon from '../../assets/icons/stackview.svg';
import PreviousCardIcon from '../../assets/icons/previous-card.svg';

export const DigestViewFooter: React.FC<{
    onPrevCard: () => void;
    onSwitchView: () => void;
    isListView: boolean;
}> = ({ onPrevCard, onSwitchView, isListView }) => {
    return (
        <div className={styles.footerContainer}>
            <button onClick={onPrevCard} className={styles.actionButton}>
                <img src={PreviousCardIcon} />
            </button>
            <button onClick={onSwitchView} className={styles.actionButton}>
                {isListView ? <img src={stackViewIcon} /> : <img src={listViewIcon} />}
            </button>
        </div>
    );
};