import React from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import styles from './Navbar.module.css'; // Import CSS for the Navbar

interface NavbarProps {
  title: string; // Title for the navbar
  profileBubble?: boolean;
  onBack?: () => void; // Optional custom back button behavior
}

const Navbar: React.FC<NavbarProps> = ({ title, profileBubble, onBack }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1); // Default behavior: go back one page
    }
  };

  return (
    <div className={styles.navbar}>
      {onBack && <button className={styles.prevButton} onClick={handleBackClick}>
        &lt;
      </button>}
      <h1 className={styles.title}>{title}</h1>
    </div>
  );
};

export default Navbar;
