import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { jwtDecode,  JwtPayload } from 'jwt-decode';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { configureApiBaseUrl } from '../config/api';
import { GET_USER } from '../graphql/queries/getUser';

/**
 * Fetch user data using Apollo Client.
 */
export const fetchUserData = async (
    client: ApolloClient<NormalizedCacheObject>
): Promise<any | null> => {
    try {
        const { data } = await client.query({
            query: GET_USER,
            fetchPolicy: 'network-only', // Always fetch fresh data
        });
        
        return data.getUser || null; // Extract user data
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
};

interface JWT extends JwtPayload {
  exp: number; // Expiration timestamp (in seconds)
  [key: string]: any; // Other JWT claims
}

/**
 * Decode a JWT and return its payload.
 */
export const decodeJWT = (token: string): any => {
  try {
    return jwtDecode<JWT>(token);
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
};

/**
 * Check if a JWT is valid by verifying its expiration time.
 */
export const isTokenValid = (token: string): boolean => {
  try {
    const decoded: JWT = jwtDecode<JWT>(token);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decoded.exp > currentTime; // Check if token is expired
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    return false;
  }
};

/**
 * Refresh an access token using the refresh token.
 */
export const refreshAccessToken = async (refreshToken: string): Promise<string | null> => {
  try {
    const API_BASE_URL = await configureApiBaseUrl();
    const response = await fetch(`${API_BASE_URL}/api/auth/refresh`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ refresh_token: refreshToken }),
    });

    if (!response.ok) {
      console.error('Failed to refresh access token:', await response.text());
      return null;
    }

    const { access_token, refresh_token } = await response.json();

    // Store new tokens
    await SecureStoragePlugin.set({ key: 'access_token', value: access_token });
    await SecureStoragePlugin.set({ key: 'refresh_token', value: refresh_token });

    return access_token;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    return null;
  }
};

/**
 * Check authentication state based on stored tokens.
 */
export const checkAuthentication = async (
): Promise<boolean> => {
    try {
        const accessTokenResult = await SecureStoragePlugin.get({ key: 'access_token' });
        const refreshTokenResult = await SecureStoragePlugin.get({ key: 'refresh_token' });
        const accessToken = accessTokenResult.value;
        const refreshToken = refreshTokenResult.value;
        
        if (accessToken && isTokenValid(accessToken)) {
            console.log('Access token is valid.');
            return true;
        } else if (refreshToken) {
            console.log('Access token expired. Attempting to refresh...');
            const newAccessToken = await refreshAccessToken(refreshToken);
            if (newAccessToken) {
                console.log('Access token refreshed successfully.');
                return true;
            } else {
                console.log('Failed to refresh access token.');
                return false;
            }
        } else {
            console.log('No valid tokens found; user is unauthenticated.');
            return false;
        }
    } catch (error) {
        console.error('Error checking authentication:', error);
        return false;
    }
};
