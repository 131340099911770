import { gql } from '@apollo/client';

export const GET_USER_EXTERNAL_CALENDAR_LIST = gql`
  query GetCalendarsList($calendar: CalendarConnectionInput!) {
  getUserExternalCalendarList(input: $calendar) {
    id
    identifier
    source
    name
  }
}
`;