import React, { useState } from "react";
import styles from "./GroupedEventItem.module.css";
import { GroupedEvent, ViewerRelationshipType } from "../../types/Digest";

type GroupedEventItemProps = {
    content: GroupedEvent;
};

const GroupedEventItem: React.FC<GroupedEventItemProps> = ({ content }) => {
    const [showOtherEvents, setShowOtherEvents] = useState(false);

    const formatTime = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };
        return date.toLocaleTimeString(undefined, options);
    };

    return (
        <div className={`${styles.digestItem}`}>
            {content.mainEvents && (
                <div className={styles.mainEventSection}>
                    {content.mainEvents.map((event) => (
                        <div key={event.eventID} className={`${styles.mainEventItem} ${styles.eventItem}`}>
                            <div className={styles.eventItemLeftSection}>
                                <div className={styles.evenItemtTitle}>{event.title || "Main Event"}</div>
                            </div>
                            <div className={styles.eventItemRightSection}>
                                <div className={styles.eventItemTime}>
                                    {formatTime(event.startDate)}{event.endDate ? ` - ${formatTime(event.endDate)}` : ""}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {content.relatedEvents && (
                <div className={styles.relatedEventSection}>
                    {content.relatedEvents.map((event) => (
                        <div key={event.eventID} className={`${styles.eventItem} ${event.owner.viewerRelationship == ViewerRelationshipType.SPOUSE ? styles.spouseRelatedEventItem : styles.relatedEventItem}`}>
                            <div className={styles.eventItemLeftSection}>
                                {event.owner.viewerRelationship !== ViewerRelationshipType.SELF && event.owner.userSummary.profilePictureUrl &&
                                    <div className={styles.eventItemProfilePictureContainer}>
                                        <img src={event.owner.userSummary.profilePictureUrl} className={styles.eventItemProfilePicture} />
                                    </div>
                                }
                                <div className={styles.evenItemtTitle}>{event.title || "Related Event"}</div>
                            </div>
                            <div className={styles.eventItemRightSection}>
                                {event.owner.viewerRelationship !== ViewerRelationshipType.SELF &&
                                    <div className={styles.eventItemCreator}>
                                        Created by: {event.owner.userSummary?.firstName // Check if firstName exists
                                            ? event.owner.userSummary.firstName
                                            : event.owner.viewerRelationship === 'SPOUSE' // Check if viewerRelationship is SPOUSE
                                                ? 'spouse'
                                                : 'other member' // Fallback if neither condition is met
                                        }
                                    </div>
                                }
                                <div className={styles.eventItemTime}>
                                    {formatTime(event.startDate)}{event.endDate ? ` - ${formatTime(event.endDate)}` : ""}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {content.otherEvents && (
                <div className={styles.otherEventSection}>
                    {content.otherEvents.map((event) => (
                        <div key={event.eventID} className={`${styles.otherEventItem} ${styles.eventItem}`}>
                            <div className={styles.eventItemLeftSection}>
                                <div className={styles.evenItemtTitle}>{event.title || "Other Event"}</div>
                            </div>
                            <div className={styles.eventItemRightSection}>
                                <div className={styles.eventItemTime}>
                                    {formatTime(event.startDate)}{event.endDate ? ` - ${formatTime(event.endDate)}` : ""}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default GroupedEventItem;
