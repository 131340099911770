import { GOOGLE_CONFIG as IOS_GOOGLE_CONFIG } from '../config/ios';
import { GOOGLE_CONFIG as ANDROID_GOOGLE_CONFIG } from '../config/android';
import { getGoogleConfig } from '../config/web';
import { isWeb, isIOS } from '../platform/platform';


export const exchangeCodeForTokens = async (code: string, codeVerifier: string) => {
    let config;        
    if (isWeb()) {
      config = getGoogleConfig();
    } else {
      if (isIOS())  {
        config = IOS_GOOGLE_CONFIG;
      } else {
        config = ANDROID_GOOGLE_CONFIG;
      }
    }
    
    const clientID = config.CLIENT_ID;
    const redirectURI = config.REDIRECT_URI;

    const response = await fetch('https://oauth2.googleapis.com/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
        code,
        client_id: clientID,        // Web or Mobile Client ID
        redirect_uri: redirectURI,  // Custom URI
        grant_type: 'authorization_code',
        code_verifier: codeVerifier,
        }).toString(),
    });

    const tokens = await response.json();
    if (!response.ok) {
        throw new Error(tokens.error || 'Failed to exchange tokens');
    }
    const { access_token, refresh_token, id_token, expires_in } = tokens;

    // Return the individual tokens as an object
    return {
        accessToken: access_token,
        refreshToken: refresh_token,
        idToken: id_token,
        expiresIn: expires_in, // Token expiry time in seconds
    };
};
  