import { isWeb, isIOS } from '../platform/platform';
import { Browser } from '@capacitor/browser';
import { generatePKCE, storeVerifier } from '../auth/pkce';
import { getGoogleConfig } from '../config/web';
import { API_BASE_URL } from '../config/api';
import { GOOGLE_CONFIG as IOS_GOOGLE_CONFIG } from '../config/ios';
import { GOOGLE_CONFIG as ANDROID_GOOGLE_CONFIG } from '../config/android';

export enum AUTH_TYPE {
    LOGIN = "LOGIN",
    CALENDAR = "CALENDAR",
};

export const handleLoginWithGoogle = async (authType: AUTH_TYPE) => {
    const config = getPlatformSpecificConfig();

    if (isWeb()) {
        console.log('Web environment detected. Redirecting to API login endpoint.');
        if (authType == AUTH_TYPE.LOGIN) {
            window.location.href = `${API_BASE_URL}/api/auth/login`;
        } else if (authType == AUTH_TYPE.CALENDAR) {
            window.location.href = `${API_BASE_URL}/api/auth/calendar`;
        }
        return;
    }

    const { CLIENT_ID, REDIRECT_URI } = config;

    // Generate PKCE code verifier and challenge
    const { codeVerifier, codeChallenge } = await generatePKCE();
    await storeVerifier(codeVerifier);

    // Construct Google OAuth URL
    const authUrl = buildGoogleAuthUrl(CLIENT_ID, REDIRECT_URI, codeChallenge, authType);

    console.log('Opening Google OAuth URL in browser:', authUrl);
    await Browser.open({ url: authUrl });
};

// Get platform-specific configuration
const getPlatformSpecificConfig = () => {
    if (isWeb()) {
        return getGoogleConfig();
    }
    return isIOS() ? IOS_GOOGLE_CONFIG : ANDROID_GOOGLE_CONFIG;
};

// Build Google OAuth URL
const buildGoogleAuthUrl = (clientID: string, redirectURI: string, codeChallenge: string, authType: AUTH_TYPE): string => {
    const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

    // Define the common parameters
    const params = new URLSearchParams({
        client_id: clientID,
        redirect_uri: redirectURI,
        response_type: 'code',
        scope: authType === AUTH_TYPE.CALENDAR
            ? 'https://www.googleapis.com/auth/calendar openid email profile'
            : 'openid email profile',
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
    });

    // Add extra parameters only for calendar auth
    if (authType === AUTH_TYPE.CALENDAR) {
        params.append('access_type', 'offline'); // Request refresh token
        params.append('approval_prompt', 'force'); // Force re-consent
        params.append('include_granted_scopes', 'true'); // Get already granted scopes also
    }

    return `${baseUrl}?${params.toString()}`;
};