import { gql } from '@apollo/client';

export const GET_SYNC_STATUS = gql`
  query GetSyncStatus($syncType: SyncType!) {
  getSyncStatus(syncType: $syncType) {
    type
    status
    syncRange {
        start
        end
    }
    createdAt
    completedAt
  }
}
`;
