import React from 'react';
import styles from './SplashScreen.module.css';
import appLogo from '../../assets/primary-logo.svg';


const SplashScreen: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logoContent}>
        <img src={appLogo} alt="Company Logo" className={styles.logo} />
      </div>
    </div>
  );
};

export default SplashScreen;
